import { DialogModel } from 'interfaces/app';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';

@Component({
	name: 'LoaderDialogView',
	emits: ['closeDialog'],
})
class LoaderDialogView extends Vue {
	@Prop({ required: true, type: Object })
	readonly dialogModel!: DialogModel;

	clickModal() {
		if (this.dialogModel.modal) {
			this.checkClose();
		}
	}

	checkClose() {
		if (this.dialogModel.hasclose) {
			this.$emit(
				'closeDialog',
				this.dialogModel,
			);
		}
	}
}

export default toNative(LoaderDialogView);
