import './defines';
import EventBus from 'components/event-bus';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import connector from 'controllers/connector';
import { ChannelModel } from 'interfaces/app';
import store, {
	ChannelsModule,
	ConfigModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import FacebookButtonView from 'views/facebook-button';
import GoogleButtonView from 'views/google-button';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		FacebookButtonView,
		GoogleButtonView,
	},
	mixins: [Template],
})
class SignupView extends Vue {
	@Prop({
		default: '',
		type: String,
	})
	public readonly firstName!: string;

	@Prop({
		default: '',
		type: String,
	})
	public readonly lastName!: string;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showForm!: boolean;

	@Prop({
		default: '',
		type: String,
	})
	public readonly username!: string;

	protected get labelName() {
		return ConfigModule.labelTagName;
	}

	private get showFirstName() {
		return store.state.config['userAccount.requiredFields'].indexOf('first_name') >= 0;
	}

	private get showLastName() {
		return store.state.config['userAccount.requiredFields'].indexOf('last_name') >= 0;
	}

	protected get hasThirdPartyLogin() {
		return this.hasGoogleLogin || this.hasFacebookLogin;
	}

	private get hasFacebookLogin() {
		return !!(
			_.findWhere(
				ChannelsModule.collection,
				{ id: 'facebook', login: 1 },
			)
		);
	}

	private inputData: {
		firstName: string;
		lastName: string;
		username: string;
		password: string;
		cpassword: string;
	} = {
			firstName: this.firstName || '',
			lastName: this.lastName || '',
			username: this.username || '',
			password: '',
			cpassword: '',
		};

	protected invalidFirstName = false;

	protected invalidLastName = false;

	protected invalidPassword = false;

	protected invalidCPassword = false;

	protected invalidUserName = false;

	private showPassword = false;

	private showCPassword = false;

	private get hasGoogleLogin() {
		return !!(
			_.findWhere(
				ChannelsModule.collection,
				{ id: 'gplus', login: 1 },
			)
		);
	}

	protected back() {
		auth.open();
	}

	protected checkKey(e: KeyboardEvent) {
		if (e.keyCode == 13) {
			this.submit();
		}
	}

	protected login() {
		auth.showLogin();
	}

	protected selectNetwork(network: ChannelModel['id']) {
		analytics.trackEvent(
			`Signup with ${network}`,
			{
				category: 'Button',
			},
		);

		// Show loader
		const closeLoader = this.$openLoaderDialog();

		// close login dialog
		auth.closeDialog();

		// Oauth dialog
		connector
			.login(
				network,
				{
					display: 'popup',
					scopeCategories: ['basic', 'login'],
				},
			)
			.then(
				() => {
					if (UserModule.id) {
						EventBus.emit(
							'auth:login',
							true,
						);
					} else {
						auth.showSignup();
					}
				},
				(err: any) => {
					auth.showSignup();

					this.$openErrorDialog({
						body: {
							content: err instanceof Error ? err.message : err,
						},
					});
				},
			)
			.finally(() => {
				// Close loader
				closeLoader();
			});
	}

	private submit() {
		analytics.trackEvent(
			'Submit signup',
			{},
		);

		this.invalidUserName = false;
		this.invalidPassword = false;

		if (this.showFirstName) {
			this.invalidFirstName = false;
		}
		if (this.showLastName) {
			this.invalidLastName = false;
		}

		const {
			firstName,
			lastName,
			password,
			username,
			cpassword,
		} = this.inputData;

		if (this.showFirstName && firstName.length === 0) {
			analytics.trackEvent(
				'Signup failed',
				{
					reason: 'Invalid first name',
				},
			);

			this.invalidFirstName = true;
		} else if (this.showLastName && lastName.length === 0) {
			analytics.trackEvent(
				'Signup failed',
				{
					reason: 'Invalid last name',
				},
			);

			this.invalidLastName = true;
		} else if (username.length === 0 || username.indexOf('@') < 0) {
			analytics.trackEvent(
				'Signup failed',
				{
					reason: 'Invalid user name',
				},
			);

			this.invalidUserName = true;
		} else if (password.length === 0) {
			analytics.trackEvent(
				'Signup failed',
				{
					reason: 'No password provided',
				},
			);

			this.invalidPassword = true;
		} else if (password !== cpassword) {
			analytics.trackEvent(
				'Signup failed',
				{
					reason: 'Provided passwords not matching',
				},
			);

			this.invalidCPassword = true;
		} else {
			// Show loader
			const closeLoader = this.$openLoaderDialog();

			// close login dialog
			auth.closeDialog();

			auth
				.signup(
					username,
					password,
					{
						first_name: firstName,
						last_name: lastName,
					},
				)
				.then(
					() => {
						if (UserModule.id) {
							analytics.trackEvent(
								'Signup',
								{},
							);

							EventBus.emit(
								'auth:login',
								true,
							);
						} else {
							analytics.trackEvent(
								'Signup failed',
								{
									reason: 'No user id returned',
								},
							);

							auth.showSignup({
								username,
								first_name: firstName,
								last_name: lastName,
							});
						}
					},
					(err: any) => {
						const errorMessage = err && err.message
							? err.message
							: err;

						analytics.trackEvent(
							'Signup failed',
							{
								reason: errorMessage,
							},
						);

						auth.showSignup({
							username,
							first_name: firstName,
							last_name: lastName,
						});

						this.$openErrorDialog({
							body: {
								content: errorMessage,
							},
						});
					},
				)
				.finally(() => {
					// Close loader
					closeLoader();
				});
		}
	}

	protected togglePasswordVisiblity() {
		this.showPassword = !this.showPassword;
	}

	protected toggleCPasswordVisiblity() {
		this.showCPassword = !this.showCPassword;
	}
}

export default toNative(SignupView);
