import type { Modules } from '@sosocio/modules';
import ProductState from 'classes/productstate';
import User from 'classes/user';
import EventBus from 'components/event-bus';
import auth from 'controllers/auth';
import navigate from 'controllers/navigate';
import * as DialogService from 'services/dialog';
import {
	ERRORS_NO_RESPONSE,
	ERRORS_OFFLINE,
} from 'settings/errors';
import { OfferingGroups } from 'settings/offerings';
import {
	AppDataModule,
	CartItemsModule,
	ProductsModule,
	ProductStateModule,
	ShippingModule,
	UserModule,
} from 'store';
import { nextTick } from 'vue';
import {
	NavigationGuard,
	NavigationGuardNext,
	RouteLocationNormalizedGeneric,
	RouteRecordRaw,
	RouterView,
} from 'vue-router';

const ConfirmModule = () => import(/* webpackChunkName: "end" */ './modules/confirm.vue');
const CartModule = () => import(/* webpackChunkName: "webshop" */ './modules/cart.vue');
const CreateModule = () => import(/* webpackChunkName: "start" */ './modules/create');
const CropItemModule = () => import(/* webpackChunkName: "crop" */ './modules/crop-item');
const CropOverviewModule = () => import(/* webpackChunkName: "crop" */ './modules/crop-overview/template.vue');
const NewEditorModule = () => import(/* webpackChunkName: "edit" */ './modules/new-editor');
const EndModule = () => import(/* webpackChunkName: "end" */ './modules/end.vue');
const ImportModule = () => import(/* webpackChunkName: "select" */ './modules/import.vue');
const LauncherModule = () => import(/* webpackChunkName: "start" */ './modules/launcher.vue');
const LibraryModule = () => import(/* webpackChunkName: "select" */ './modules/library');
const OauthModule = () => import(/* webpackChunkName: "start" */ './modules/oauth.vue');
const OrderStatusModule = () => import(/* webpackChunkName: "account" */ './modules/orderstatus.vue');
const OverviewModule = () => import(/* webpackChunkName: "edit" */ './modules/overview');
const PreviewModule = () => import(/* webpackChunkName: "edit" */ './modules/preview.vue');
const SelectModule = () => import(/* webpackChunkName: "select" */ './modules/select.vue');
const ShelfModule = () => import(/* webpackChunkName: "start" */ './modules/shelf.vue');
const ShowcaseModule = () => import(/* webpackChunkName: "showcase" */ './modules/showcase.vue');
const SortModule = () => import(/* webpackChunkName: "edit" */ './modules/sort');
const UserAccountModule = () => import(/* webpackChunkName: "account" */ './modules/account.vue');
const UserAccountPhotosModule = () => import(/* webpackChunkName: "account" */ './modules/account-photos.vue');
const WebshopModule = () => import(/* webpackChunkName: "webshop" */ './modules/webshop.vue');
const WebshopConfirmModule = () => import(/* webpackChunkName: "webshop" */ './modules/webshop-confirm.vue');
const WebshopPaymentModule = () => import(/* webpackChunkName: "webshop" */ './modules/webshop-payment.vue');
const WebshopResultModule = () => import(/* webpackChunkName: "webshop" */ './modules/webshop-result.vue');
const ExternalUploadModule = () => import(/* webpackChunkName: "externalupload" */ './modules/external-upload');

function productAccessErrorHandler(
	err: Error,
	next: NavigationGuardNext,
) {
	let errorMessage = err.message;

	if (
		err.message === ERRORS_NO_RESPONSE
		|| err.message === ERRORS_OFFLINE
	) {
		errorMessage = window.App.router.$t('dialogTextLoadError');
	}

	const closeError = DialogService.openErrorDialog({
		header: {
			hasCloseButton: false,
		},
		body: {
			content: errorMessage,
		},
		footer: {
			buttons: [
				{
					id: 'cancel',
					text: window.App.router.$t('dialogButtonCancel'),
					click: () => {
						next(false);
						navigate.toStart();
						closeError();
					},
				},
				{
					id: 'accept',
					text: window.App.router.$t('dialogButtonOfflineOk'),
					click: () => {
						next(false);
						window.location.reload();
						closeError();
					},
				},
			],
		},
	});
}

const productAccess: NavigationGuard = (to, from, next) => {
	// This route is only available to logged in users
	if (!UserModule.id) {
		EventBus.once(
			'auth:login',
			(success: boolean) => {
				if (success) {
					ProductState
						.setup(parseInt(
							to.params.productid as string,
							10,
						))
						.then(() => {
							next();
						})
						.catch((err) => {
							productAccessErrorHandler(
								err,
								next,
							);
						});
				} else {
					next(false);
					navigate.toStart();
				}
			},
		);

		auth.showLogin({
			hasclose: false,
		});
	} else {
		ProductState
			.setup(parseInt(
				to.params.productid as string,
				10,
			))
			.then(() => {
				next();
			})
			.catch((err) => {
				productAccessErrorHandler(
					err,
					next,
				);
			});
	}
};

const productSetup: NavigationGuard = (to, from, next) => {
	if (UserModule.id) {
		ProductState
			.setup(parseInt(
				to.params.productid as string,
				10,
			))
			.finally(() => {
				next();
			});
	} else {
		next();
	}
};

const saveProduct: NavigationGuard = (to, from, next) => {
	ProductState.finalize(
		true,
		true,
	).then(
		(saved) => {
			if (saved) {
				next();
			} else {
				next(false);
				window.App.router.reload();
			}
		},
		(err) => {
			next(false);

			if (!UserModule.id) {
				// User not logged in, discard this project
				ProductStateModule.reset();
				navigate.toStart();
			} else {
				const closeError = DialogService.openErrorDialog({
					header: {
						hasCloseButton: false,
					},
					body: {
						content: (
							err && err.message
								? err.message
								: window.App.router.$t('dialogTextError')
						),
					},
					footer: {
						buttons: [
							{
								id: 'delete',
								text: window.App.router.$t('buttonDiscardChanges'),
								click: () => {
									ProductStateModule.reset();
									navigate.toStart();
									closeError();
								},
							},
							{
								id: 'retry',
								text: window.App.router.$t('dialogButtonErrorRetry'),
								click: () => {
									window.App.router.reload();
									closeError();
								},
							},
						],
					},
				});
			}
		},
	);
};

const userAccess: NavigationGuard = (to, from, next) => {
	// This route is only available to logged in users
	if (!UserModule.id) {
		EventBus.once(
			'auth:login',
			(success: boolean) => {
				if (success) {
					next();
				} else {
					next(false);
					navigate.toStart();
				}
			},
		);

		auth.showLogin({
			hasclose: false,
		});
	} else {
		ProductState
			.finalize(
				true,
				false,
			)
			.then(
				(saved) => {
					if (saved) {
						next();
					} else {
						next(false);
					}
				},
				() => {
					next(false);
					window.App.router.reload();
				},
			);
	}
};

const isAddedToCart: NavigationGuard = (to, from, next) => {
	productAccess(
		to,
		from,
		(result?) => {
			if (result === false) {
				next(false);
			} else {
				const cartItem = CartItemsModule.findWhere({
					productid: parseInt(
						to.params.productid as string,
						10,
					),
				});
				if (cartItem) {
					next();
				} else {
					next(false);
					navigate.toEnd(parseInt(
						to.params.productid as string,
						10,
					));
				}
			}
		},
	);
};

const loadShippingData: NavigationGuard = (to, from, next) => {
	if (!UserModule.id) {
		EventBus.once(
			'auth:login',
			(success: boolean) => {
				if (success) {
					next();
				} else {
					next(false);
					navigate.toStart();
				}
			},
		);

		auth.showLogin({
			hasclose: false,
		});
	} else {
		ProductState
			.finalize(
				true,
				false,
			)
			.then((saved) => {
				if (saved) {
					if (!ShippingModule.fetched) {
						const closeLoader = DialogService.openLoaderDialog();
						return User
							.save()
							.then(() => (
								ShippingModule.fetchCartShippingData({
									requestOptions: {
										params: {
											limit: 100,
										},
									},
								})
							))
							.then(next)
							.finally(closeLoader);
					}

					return next();
				}

				return next(false);
			}).catch((err) => {
				const closeError = DialogService.openErrorDialog({
					header: {
						hasCloseButton: false,
					},
					body: {
						content: (
							err && err.message
								? err.message
								: window.App.router.$t('dialogTextError')
						),
					},
					footer: {
						buttons: [
							{
								id: 'retry',
								text: window.App.router.$t('dialogButtonErrorRetry'),
								click: () => {
									next(false);
									window.location.reload();
									closeError();
								},
							},
						],
					},
				});
			});
	}
};

const showcaseAccess: NavigationGuard = (to, from, next) => {
	ProductsModule
		.addModel({
			id: parseInt(
				to.params.productid as string,
				10,
			),
			read_token: to.params.token as string,
		})
		.then(() => ProductState.setup(
			parseInt(
				to.params.productid as string,
				10,
			),
			{
				ajaxOptions: {
					headers: {
						'x-product-id': to.params.productid,
						'X-product-readtoken': to.params.token,
					},
				},
			},
		))
		.then(
			() => {
				next();
			},
			() => {
				const closeAlert = DialogService.openAlertDialog({
					header: {
						title: window.App.router.$t('dialogHeaderNoAccess'),
					},
					body: {
						content: window.App.router.$t('dialogTextNoAccess'),
					},
					footer: {
						buttons: [
							{
								id: 'accept',
								text: window.App.router.$t('dialogButtonNoAccessOk'),
								click: () => {
									// Product no longer exists, redirect to homepage
									window.location.href = '/';
									closeAlert();
								},
							},
						],
					},
				});

				next(false);
			},
		)
		.catch((err) => {
			DialogService.openErrorDialog({
				body: {
					content: err.message,
				},
			});
		});
};

const loadOfferings = async (to: RouteLocationNormalizedGeneric) => {
	if (to.params.pdpid) {
		const pdpid = parseInt(
			to.params.pdpid as string,
			10,
		);
		/**
		 * Check for offerings loaded in memory based on `pdpid`
		 * and store a boolean only if there is only one offering
		 * found or none
		 */
		const fetchOfferings = AppDataModule.findOffering({
			pdpid,
		}).length !== AppDataModule.pdps.find((pdp) => pdp.id === pdpid)?.offeringscount;

		/**
		 * In case a single offering is found or none, fetch the offerings
		 * data from the server based on `pdpid`
		 */
		if (fetchOfferings) {
			await AppDataModule.fetchOfferingsData({
				searchProps: {
					pdpid: parseInt(
						to.params.pdpid as string,
						10,
					),
				},
			});
		}
	}
	if (to.params.offeringid) {
		const offeringid = parseInt(
			to.params.offeringid as string,
			10,
		);
		/**
		 * Check for a single offering loaded in memory based on its `id`
		 */
		const offeringFound = AppDataModule.findOfferingWhere({
			id: offeringid,
		});
		let fetchOfferings = false;

		if (!offeringFound) {
			/**
			 * If no offering is found, then indicate to fetch the offerings data
			 */
			fetchOfferings = true;
		} else if (offeringFound.flexgroupid) {
			/**
			 * If a single offering is found and it has a `flexgroupid`, then check
			 * for offerings loaded in memory based on `flexgroupid` and set the
			 * boolean only if there is only one offering found or none
			 */
			fetchOfferings = AppDataModule.findOffering({
				flexgroupid: offeringFound.flexgroupid,
			}).length !== AppDataModule.flexgroups.find((flexgroup) => flexgroup.id === offeringFound.flexgroupid)?.offeringscount;
		}

		/**
		 * If the `fetchOfferings` flag is set to `true`, then fetch the offerings
		 * data from the server based on the offering id but indicating to get
		 * the data from the flexgroupid of the offering
		 */
		if (fetchOfferings) {
			await AppDataModule.fetchOfferingsData({
				searchProps: {
					id: parseInt(
						to.params.offeringid as string,
						10,
					),
					get_from_flexgroupid: true,
				},
			});
		}
	}

	return nextTick();
};

const routes: RouteRecordRaw[] = [
	{
		path: '/showcase/:productid/:token',
		component: ShowcaseModule,
		beforeEnter: showcaseAccess,
	},
	{
		path: '/create',
		component: CreateModule,
		beforeEnter: saveProduct,
		children: [
			{
				path: ':listeritemid',
				component: CreateModule,
				beforeEnter: saveProduct,
				children: [
					{
						path: ':offeringid',
						component: CreateModule,
						beforeEnter: async (to, from, next) => {
							await loadOfferings(to);

							return saveProduct(
								to,
								from,
								next,
							);
						},
						children: [
							{
								path: ':themecatid',
								component: CreateModule,
								beforeEnter: async (to, from, next) => {
									await loadOfferings(to);

									return saveProduct(
										to,
										from,
										next,
									);
								},
								children: [
									{
										path: ':themeid',
										component: CreateModule,
										beforeEnter: async (to, from, next) => {
											await loadOfferings(to);

											return saveProduct(
												to,
												from,
												next,
											);
										},
									},
								],
							},
						],
					},
				],
				meta: {
					keepScrollPosition: true,
				},
			},
		],
		meta: {
			keepScrollPosition: true,
			alsoKnownAs: ['/'],
		},
	},
	{
		path: '/pdp/:pdpid',
		component: CreateModule,
		beforeEnter: async (to, from, next) => {
			await loadOfferings(to);

			return saveProduct(
				to,
				from,
				next,
			);
		},
	},
	{
		path: '/cart',
		component: CartModule,
		beforeEnter: loadShippingData,
	},
	{
		path: '/webshop/confirm',
		component: WebshopConfirmModule,
		beforeEnter: loadShippingData,
	},
	{
		path: '/webshop/payment',
		component: WebshopPaymentModule,
		beforeEnter: userAccess,
	},
	{
		path: '/webshop/:status',
		component: WebshopResultModule,
		beforeEnter: userAccess,
		children: [
			{
				path: ':orderid',
				component: WebshopResultModule,
				beforeEnter: userAccess,
			},
		],
	},
	{
		path: '/webshop',
		component: WebshopModule,
		beforeEnter: loadShippingData,
	},
	{
		path: '/oauth/:network',
		component: OauthModule,
	},
	{
		path: '/account/order/:orderid',
		component: OrderStatusModule,
		beforeEnter: userAccess,
	},
	{
		path: '/account/photos',
		component: UserAccountPhotosModule,
		beforeEnter: userAccess,
	},
	{
		path: '/account',
		component: UserAccountModule,
		beforeEnter: userAccess,
	},
	{
		path: '/launch/:offeringid',
		component: LauncherModule,
		beforeEnter: async (to, from, next) => {
			await loadOfferings(to);

			return next();
		},
	},
	{
		path: '/:productid/open',
		/**
		 * If the router detects a route without a `component` or
		 * `children` property it skips the route when adding it
		 */
		component: RouterView,
		beforeEnter(to, from, next) {
			const query = { ...to.query };
			/**
			 * We need to wait until the route is processed so `this.$route` inside the
			 * `AppRouterView` component resolves correctly to the route being navigated to
			 */
			const afterEachUnregister = window.App.router.$router.afterEach(() => {
				afterEachUnregister();
				window.App.router.openProduct(
					parseInt(
						to.params.productid as string,
						10,
					) as number | 'latest',
					true,
					query?.primaryAction as 'extendProjectLifetime' | 'removeProject',
				);
			});
			next();
		},
	},
	{
		path: '/:productid/launch',
		component: LauncherModule,
		beforeEnter: productAccess,
	},
	{
		path: '/:productid/select/:source',
		component: ImportModule,
		beforeEnter: productAccess,
	},
	{
		path: '/:productid/library',
		component: LibraryModule,
		beforeEnter: productAccess,
		children: [
			{
				path: ':photoindex',
				component: LibraryModule,
				beforeEnter: productAccess,
			},
		],
	},
	{
		path: '/:productid/select',
		component: SelectModule,
		beforeEnter: productAccess,
	},
	{
		path: '/:productid/preview',
		component: PreviewModule,
		beforeEnter: productAccess,
		children: [
			{
				path: ':pagenr',
				component: PreviewModule,
				beforeEnter: productAccess,
			},
		],
	},
	{
		name: 'Pages Overview',
		path: '/:productid/overview',
		alias: '/:productid/overview*', // Alias for backward support for overview route
		component: OverviewModule,
		beforeEnter: productAccess,
	},
	{
		name: 'Sort',
		path: '/:productid/sort',
		component: SortModule,
		beforeEnter: productAccess,
	},
	{
		/**
		 * Used to detect if new support button design
		 * should be used in the `SupportView` component
		 */
		name: 'New Editor Module',
		path: '/:productid/edit/:pagenr',
		component: NewEditorModule,
		beforeEnter: productAccess,
		props: () => {
			const projectModel = ProductStateModule.getProduct;
			const isDarkTheme = (
				typeof projectModel?.group !== 'undefined'
				&& OfferingGroups(
					projectModel.group,
					[
						'SingleLayer',
					],
				)
			);
			const props: PublicNonVueOptionalNonFunctionProps<Modules['@sosocio-components/new-editor']> = {
				theme: (
					isDarkTheme
						? 'dark'
						: 'light'
				),
			};

			return props;
		},
	},
	{
		path: '/:productid/crop/:objectid',
		component: CropItemModule,
		beforeEnter: productAccess,
	},
	{
		path: '/:productid/crop',
		component: CropOverviewModule,
		beforeEnter: productAccess,
	},
	{
		path: '/:productid/end',
		component: EndModule,
		beforeEnter: productAccess,
	},
	{
		path: '/:productid/confirm',
		component: ConfirmModule,
		beforeEnter: isAddedToCart,
	},
	{
		path: '/:productid/externalupload',
		component: ExternalUploadModule,
		beforeEnter: productSetup,
	},
	{
		path: '',
		component: ShelfModule,
		beforeEnter: saveProduct,
		meta: {
			keepScrollPosition: true,
		},
	},
];

export default routes;
