import './defines';
import EventBus from 'components/event-bus';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import connector from 'controllers/connector';
import { ChannelModel } from 'interfaces/app';
import URL_PARAMETERS from 'settings/url-parameters';
import {
	ChannelsModule,
	ConfigModule,
	UserModule,
} from 'store';
import parseUrl from 'tools/parse-url';
import _ from 'underscore';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import FacebookButtonView from 'views/facebook-button';
import GoogleButtonView from 'views/google-button';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		FacebookButtonView,
		GoogleButtonView,
	},
	mixins: [Template],
})
class LoginView extends Vue {
	@Prop({
		default: true,
		type: Boolean,
	})
	public readonly showForm!: boolean;

	@Prop({
		default: null,
		type: String,
	})
	public readonly username!: string | null;

	private get connections() {
		const connections: ChannelModel['id'][] = [];
		if (this.hasFacebookLogin) {
			connections.push('facebook');
		}
		if (this.hasGoogleLogin) {
			connections.push('gplus');
		}

		return connections;
	}

	protected get hasThirdPartyLogin() {
		return this.hasGoogleLogin || this.hasFacebookLogin;
	}

	private get hasFacebookLogin() {
		return !!(
			_.findWhere(
				ChannelsModule.collection,
				{ id: 'facebook', login: 1 },
			)
		);
	}

	private get hasGoogleLogin() {
		return !!(
			_.findWhere(
				ChannelsModule.collection,
				{ id: 'gplus', login: 1 },
			)
		);
	}

	protected get labelName() {
		return ConfigModule.labelTagName;
	}

	private inputData: {
		username: string | null;
		password: string | null;
	} = {
			username: null,
			password: null,
		};

	protected invalidPassword = false;

	protected invalidUserName = false;

	private showPassword = false;

	protected created() {
		const parsedUrl = parseUrl(window.location.href);
		if (parsedUrl.parameters.hasOwnProperty(URL_PARAMETERS.email)) {
			this.inputData.username = parsedUrl.parameters[URL_PARAMETERS.email];
		}
	}

	protected mounted() {
		if (this.username) {
			this.inputData.username = this.username;
		}

		this.connections.forEach((channelId) => {
			connector
				.setup(channelId)
				.then(() => connector.init(channelId))
				.catch(() => {
					// Swallow error: no action required
				});
		});
	}

	protected back() {
		auth.open();
	}

	protected cancel() {
		auth.cancel();
	}

	protected checkKey(e: KeyboardEvent) {
		if (e.keyCode == 13) {
			this.checkLogin();
		}
	}

	private checkLogin() {
		analytics.trackEvent(
			'Submit login',
			{},
		);

		this.invalidUserName = false;
		this.invalidPassword = false;

		const {
			username,
			password,
		} = this.inputData;

		if (
			!password
			|| password.length === 0
		) {
			analytics.trackEvent(
				'Login failed',
				{
					reason: 'No password provided',
				},
			);

			this.invalidPassword = true;
		} else if (
			!username
			|| username.length === 0
			|| username.indexOf('@') === -1
		) {
			this.invalidUserName = true;

			analytics.trackEvent(
				'Login failed',
				{
					reason: 'Invalid user name',
				},
			);
		} else {
			// Show loader
			const closeLoader = this.$openLoaderDialog();

			// close login dialog
			auth.closeDialog();

			auth
				.login({
					username,
					password,
				})
				.then(
					() => {
						if (UserModule.id) {
							analytics.trackEvent(
								'Login',
								{},
							);

							EventBus.emit(
								'auth:login',
								true,
							);
						} else {
							analytics.trackEvent(
								'Login failed',
								{
									reason: 'No user id returned',
								},
							);

							auth.showLogin({
								username,
							});
						}
					},
					(err) => {
						const errorMessage = err instanceof Error
							? err.message
							: err;

						auth.showLogin({
							username,
						});

						this.$openErrorDialog({
							body: {
								content: errorMessage,
							},
						});

						analytics.trackEvent(
							'Login failed',
							{
								reason: errorMessage,
							},
						);
					},
				)
				.finally(() => {
					// Close loader
					closeLoader();
				});
		}
	}

	protected forgotPassword() {
		const { username } = this.inputData;

		analytics.trackEvent(
			'Forgot password',
			{},
		);

		if (
			username
			&& username.length > 0
			&& username.indexOf('@') >= 0
		) {
			// Show loader
			const closeLoader = this.$openLoaderDialog();

			// close login dialog
			auth.closeDialog();

			auth
				.forgotPassword(username)
				.then(
					() => {
						// Show message saying password reset email has been send
						const closeAlert = this.$openAlertDialog({
							header: {
								title: this.$t('dialogHeaderForgotPassword'),
							},
							body: {
								content: this.$t('dialogTextForgotPassword'),
							},
							footer: {
								buttons: [
									{
										id: 'accept',
										text: this.$t('dialogButtonForgotPasswordOk'),
										click: () => {
											auth.showLogin({
												username,
											});
											closeAlert();
										},
									},
								],
							},
						});
					},
					(error) => {
						const closeError = this.$openErrorDialog({
							body: {
								content: error.message,
							},
							footer: {
								buttons: [
									{
										id: 'accept',
										text: this.$t('dialogButtonOk'),
										click: () => {
											auth.showLogin({
												username,
											});
											closeError();
										},
									},
								],
							},
						});
					},
				)
				.finally(() => {
					// Close loader
					closeLoader();
				});
		} else {
			this.invalidUserName = true;
		}
	}

	protected selectNetwork(network: ChannelModel['id']) {
		const networkModel = ChannelsModule.getById(network);
		if (!networkModel) {
			throw new Error('Could not find required network model');
		}

		analytics.trackEvent(
			`Login with ${network}`,
			{
				category: 'Button',
			},
		);

		// Close login dialog
		auth.closeDialog();

		const closeLoader = this.$openLoaderDialog();

		// Oauth dialog
		connector
			.setup(networkModel.id)
			.then(() => connector.status(networkModel.id))
			.then(
				() => {
					if (UserModule.id) {
						EventBus.emit(
							'auth:login',
							true,
						);
					} else {
						auth.showLogin();
					}
				},
				() => {
					connector
						.login(
							networkModel.id,
							{
								display: 'popup',
								scopeCategories: ['basic', 'login'],
							},
						)
						.then(
							() => {
								if (UserModule.id) {
									EventBus.emit(
										'auth:login',
										true,
									);
								} else {
									auth.showLogin();
								}
							},
							(err) => {
								auth.showLogin();
								this.$openErrorDialog({
									body: {
										content: err instanceof Error ? err.message : err,
									},
								});
							},
						)
						// Close loader
						.finally(closeLoader);
				},
			);
	}

	protected signup() {
		auth.showSignup();
	}

	protected togglePasswordVisiblity() {
		this.showPassword = !this.showPassword;
	}
}

export default toNative(LoginView);
