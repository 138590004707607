import './defines';
import SwitchBoxComponent from 'components/switch-box';
import { ConfigModule } from 'store';
import { Model } from 'utils/decorators';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'SmartEnhancementView',
	components: {
		SwitchBoxComponent,
	},
	mixins: [Template],
})
class SmartEnhancementView extends Vue {
	@Model({
		default: undefined,
		description: 'Defines the enhancement value model (used for the switch box component)',
		type: Boolean,
	})
	public readonly value?: boolean;

	@Prop({
		default: false,
		description: 'Defines if the switch box should be shown',
		type: Boolean,
	})
	public readonly showSwitchBox?: boolean;

	protected imageUrlAfter: string | null = null;

	protected imageUrlBefore: string | null = null;

	private internalValue = false;

	protected beforeCreate() {
		const labelDirectory = (
			ConfigModule.partnerID === 2
				? 'hema/'
				: ''
		);

		import(/* webpackMode: "eager" */ `../../../img/smart-enhancement/${labelDirectory}before.jpg`)
			.then((imageUrl) => {
				this.imageUrlBefore = imageUrl.default;
			});
		import(/* webpackMode: "eager" */ `../../../img/smart-enhancement/${labelDirectory}after.jpg`)
			.then((imageUrl) => {
				this.imageUrlAfter = imageUrl.default;
			});
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		if (typeof this.value !== 'undefined') {
			this.internalValue = this.value;
		}
	}

	protected onSmartEnhancementChange(value: boolean): void {
		this.internalValue = value;
		this.$emit(
			'update:value',
			this.internalValue,
		);
	}
}

export default toNative(SmartEnhancementView);
