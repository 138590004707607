import _ from 'underscore';
import merge from 'deepmerge';
import * as DB from 'interfaces/database';
import {
	BulkProductTypeModel,
	PricingObject,
} from 'interfaces/app';
import { OfferingCountForBulkDiscount, OfferingGroups } from '../settings/offerings';
import compileBusinessDays from '../tools/compile-business-days';
import {
	DiscountModule,
	CartItemsModule,
	ProductsModule,
	ThemeStateModule,
	ProductStateModule,
	AddressesModule,
	ShippingModule,
	UserModule,
	AppDataModule,
	CartModule,
} from '../store/index';

interface BulkStateModel {
	id: number;
	bulkModel: DB.BulkModel;
	typeModel: DB.BulkProductTypeModel;
	quantityModel: DB.BulkQuantityModel;
	value: number;
}

export interface DiscountItem {
	cartItemModel: DB.ShoppingCartItemModel;
	pagesDiscountQuantity: number;
	baseDiscountValue: number;
	pagesDiscountValue: number;
	shippingDiscountValue: number;
	handlingDiscountValue: number;
}

export default class PriceCalculator {
	static cartHandling() {
		// Get shopping cart state
		let handlingValue = 0;

		// Get items that are available in current region
		const cartItemCollection = CartItemsModule.getByCountryId(UserModule.countryid);

		const handling: {
			[key: string]: number;
		} = {};

		// Store handling price per item in array
		const objHandlingPerItem: {
			[key: string]: number;
		} = {};

		// Get handling cost for each cart item
		_.each(
			cartItemCollection,
			(cartItemModel) => {
			// Request product price
				const price = PriceCalculator.projectPrice({
					cartItemModel,
				});

				if (!price) {
					throw new Error('Could not get product price while calculating cart value');
				}

				let handlingTag: string;
				let handlingQuantity: number;

				if (price.handlingModel) {
					switch (price.handlingModel.scope) {
						case 'quantity':
							handlingTag = `item${cartItemModel.id}`;
							handlingQuantity = cartItemModel.quantity;
							break;
						case 'item':
							handlingTag = `item${cartItemModel.id}`;
							handlingQuantity = 1;
							break;
						case 'variant':
							handlingTag = `${cartItemModel.groupid}${cartItemModel.typeid}${cartItemModel.variantid}`;
							handlingQuantity = 1;
							break;
						case 'type':
							handlingTag = `${cartItemModel.groupid}${cartItemModel.typeid}`;
							handlingQuantity = 1;
							break;
						case 'group':
							handlingTag = cartItemModel.groupid.toString();
							handlingQuantity = 1;
							break;
						case 'order':
							handlingTag = 'order';
							handlingQuantity = 1;
							break;
						default:
							throw new Error(`Unknown handling scope: ${price.handlingModel.scope}`);
					}

					let extraHandlingPrice = 0;
					if (handling.hasOwnProperty(handlingTag)) {
						extraHandlingPrice = handling[handlingTag] - price.handlingModel.price * handlingQuantity;
					} else {
						handling[handlingTag] = 0;
						extraHandlingPrice = price.handlingModel.price * handlingQuantity;
					}
					handling[handlingTag] += extraHandlingPrice;
					objHandlingPerItem[cartItemModel.id] = extraHandlingPrice;
				}
			},
		);

		// Calculate to handling cost
		handlingValue += _.reduce(
			_.values(handling),
			(memo, num) => memo + num,
			0,
		);

		return {
			handlingValue,
			objHandlingPerItem,
		};
	}

	static cartShipping(opts?: {
		tracking?: 0|1;
		express?: 0|1;
	}) {
		// Set default options
		const defaults = {};
		const options: {
			tracking?: 0|1;
			express?: 0|1;
		} = opts
			? merge(
				defaults,
				opts,
			)
			: defaults;

		const countryModel = UserModule.countryid
			? AppDataModule.getCountry(UserModule.countryid)
			: undefined;
		let shippingValue = 0;
		let available = true;
		let selected = true;
		const deliveryTime = {
			min: 0,
			max: 0,
		};

		// Store shipping price per item in array
		const objShippingPerItem: {
			[key: string]: number;
		} = {};

		// Get items that are available in current region
		const cartItemCollection = CartItemsModule.getByCountryId(UserModule.countryid);

		const shipping: {
			[key: string]: number;
		} = {};

		// Get shipping cost for each cart item
		_.each(
			cartItemCollection,
			(cartItemModel) => {
			// Request shipping data
				const objParams: {
					cartItemModel: DB.ShoppingCartItemModel;
					tracking?: 0|1;
					express?: 0|1;
				} = {
					cartItemModel,
				};

				if (options.tracking !== undefined) {
					objParams.tracking = options.tracking;
				}
				if (options.express !== undefined) {
					objParams.express = options.express;
				}

				const objShipping = PriceCalculator.productShipping(objParams);

				if (objShipping.shippingModel) {
				// Set flag if requested method is currently selected
					selected = selected
						? !!(cartItemModel.shippingid && cartItemModel.shippingid == objShipping.shippingModel.id)
						: false;

					let shippingTag;
					let shippingQuantity;
					switch (objShipping.shippingModel.scope) {
						case 'quantity':
							shippingTag = `item${cartItemModel.id}`;
							shippingQuantity = cartItemModel.quantity;
							break;
						case 'item':
							shippingTag = `item${cartItemModel.id}`;
							shippingQuantity = 1;
							break;
						case 'variant':
							shippingTag = `${cartItemModel.groupid}${cartItemModel.typeid}${cartItemModel.variantid}`;
							shippingQuantity = 1;
							break;
						case 'type':
							shippingTag = `${cartItemModel.groupid}${cartItemModel.typeid}`;
							shippingQuantity = 1;
							break;
						case 'group':
							shippingTag = cartItemModel.groupid;
							shippingQuantity = 1;
							break;
						case 'order':
							shippingTag = 'order';
							shippingQuantity = 1;
							break;
						default:
							throw new Error(`Unknown shipping scope: ${objShipping.shippingModel.scope}`);
					}

					if (objShipping.shippingModel.days_min) {
						deliveryTime.min = Math.max(
							deliveryTime.min,
							objShipping.shippingModel.days_min,
						);
					}
					if (objShipping.shippingModel.days_max) {
						deliveryTime.max = Math.max(
							deliveryTime.max,
							objShipping.shippingModel.days_max,
						);
					}

					let extraShippingPrice = 0;
					if (shipping.hasOwnProperty(shippingTag)) {
						extraShippingPrice = Math.max(
							0,
							objShipping.shippingModel.sale * shippingQuantity - shipping[shippingTag],
						);
					} else {
						shipping[shippingTag] = 0;
						extraShippingPrice = objShipping.shippingModel.sale * shippingQuantity;
					}
					shipping[shippingTag] += extraShippingPrice;
					objShippingPerItem[cartItemModel.id] = extraShippingPrice;
				} else {
					available = false;
				}
			},
		);

		// Calculate total shipping cost
		shippingValue += _.reduce(
			_.values(shipping),
			(memo, num) => memo + num,
			0,
		);

		const deliveryEstimate = countryModel && deliveryTime.min && deliveryTime.max ? {
			min: compileBusinessDays(
				null,
				deliveryTime.min,
				countryModel.iso,
				'MMMM D',
			),
			max: compileBusinessDays(
				null,
				deliveryTime.max,
				countryModel.iso,
				'MMMM D',
			),
		} : null;

		return {
			available,
			selected: selected && available,
			shippingValue,
			objShippingPerItem,
			deliveryTime,
			deliveryEstimate,
		};
	}

	static cartValue() {
		// Get items that are available in current region
		const cartItemCollection = CartItemsModule.getByCountryId(UserModule.countryid);

		// Calculate total value of cart items
		let cartValue = 0;
		_.each(
			cartItemCollection,
			(cartItemModel) => {
				if (!cartItemModel.shoppingcartitemid) {
				// Request product price
					const price = PriceCalculator.projectPrice({
						cartItemModel,
					});

					if (price) {
					// Add cartitem value to cart value
						cartValue += cartItemModel.quantity * price.subTotal;
						cartValue += price.costHandling || 0;
						cartValue += price.costUpsell;
					} else if (typeof window.glBugsnagClient !== 'undefined') {
						const e = new Error('Could not get product price while calculating cart value');
						window.glBugsnagClient.notify(
							e,
							(event) => { event.severity = 'warning'; },
						);
					}
				}
			},
		);

		return cartValue;
	}

	static bulkDiscount() {
		const cartItemCollection = CartItemsModule.collection;
		const bulkStateCollection: BulkStateModel[] = [];
		let bulkValue = 0;
		const bulkproducttypes: BulkProductTypeModel[] = [];

		_.each(
			AppDataModule.bulkproducttypes,
			(model) => {
			// Create clone of the model
				bulkproducttypes.push(JSON.parse(JSON.stringify(model)));
			},
		);

		_.each(
			cartItemCollection,
			(cartItemModel) => {
			// Add cart item quantity to related bulk product type
				_.each(
					_.where(
						bulkproducttypes,
						{
							groupid: cartItemModel.groupid,
							typeid: cartItemModel.typeid,
							variantid: cartItemModel.variantid,
						},
					),
					(bulkproducttype) => {
						const q = OfferingGroups(
							cartItemModel.groupid,
							['PrintTypes'],
						)
							? cartItemModel.pages * cartItemModel.quantity
							: cartItemModel.quantity;
						bulkproducttype.quantity = bulkproducttype.quantity
							? bulkproducttype.quantity + q
							: q;
					},
				);
			},
		);

		// Filter items that may be eligible for a bulk discount
		const filter = bulkproducttypes.filter(
			(bulkproducttype) => bulkproducttype.quantity && bulkproducttype.quantity > 0,
		);

		// Filter items that fall within range of related bulk discount
		filter.forEach((bulkproducttype) => {
			const { bulkid } = bulkproducttype;
			const bulkquantities = AppDataModule.findBulkQuantity({
				bulkid,
			});
			const bulkquantity = bulkquantities.filter(
				(quantityModel) => (
					bulkproducttype.quantity
					&& bulkproducttype.quantity >= quantityModel.from
					&& bulkproducttype.quantity <= quantityModel.to
				),
			);
			if (bulkquantity.length > 0) {
				const perc = bulkquantity[0].relative / 100;
				const bulkModel = AppDataModule.getBulk(bulkid);
				if (bulkModel) {
					const bulkStateModel: BulkStateModel = {
						id: bulkid,
						bulkModel,
						typeModel: bulkproducttype,
						quantityModel: bulkquantity[0],
						value: 0,
					};
					_.each(
						_.where(
							cartItemCollection,
							{
								groupid: bulkproducttype.groupid,
								typeid: bulkproducttype.typeid,
								variantid: bulkproducttype.variantid,
							},
						),
						(cartItemModel) => {
							const price = PriceCalculator.projectPrice({ cartItemModel });
							const value = perc * cartItemModel.quantity * price.subTotal;
							bulkValue += value;
							bulkStateModel.value += value;
						},
					);
					bulkStateCollection.push(bulkStateModel);
				}
			}
		});

		return {
			bulkValue: Math.ceil(bulkValue),
			bulkStateCollection,
		};
	}

	static discountVoucher(
		bulkStateCollection: BulkStateModel[],
		maxdiscount: number,
		objShippingPerItem: {
			[key: string]: number;
		},
		objHandlingPerItem: {
			[key: string]: number;
		},
	) {
		const cartItemCollection = CartItemsModule.collection;
		const voucherModel = DiscountModule.getVoucher;
		const discountModel = DiscountModule.getDiscount;
		const discountProductTypes = DiscountModule.getProductTypes;
		let discountQuantity = discountModel.quantity;

		let totalDiscountValue = 0;
		let totalShippingDiscountValue = 0;
		let totalHandlingDiscountValue = 0;
		const arrItems: DiscountItem[] = [];

		if (voucherModel.credit_quantity && voucherModel.credit_quantity > 0) {
			discountQuantity = voucherModel.credit_quantity;
		}

		if (voucherModel.credit_absolute && voucherModel.credit_absolute > 0 && maxdiscount) {
			return {
				discountModel,
				arrItems,
				totalShippingDiscountValue: 0,
				totalHandlingDiscountValue: 0,
				totalDiscountValue: Math.min(
					maxdiscount,
					voucherModel.credit_absolute,
				),
			};
		} if (discountModel.absolute) {
			totalDiscountValue += discountModel.absolute;
		}

		_.each(
			discountProductTypes,
			(productTypeModel) => {
				if (!discountModel.absolute || discountModel.absolute === 0) {
				// relative discount can be set to 0, but still have free shipping or free handling,
				// therefore we check if absolute discount has not been set
					const validproducts = _.where(
						cartItemCollection,
						{
							groupid: productTypeModel.groupid,
							typeid: productTypeModel.typeid,
							variantid: productTypeModel.variantid,
						},
					);

					_.each(
						validproducts,
						(cartItemModel) => {
							const price = PriceCalculator.projectPrice({ cartItemModel });
							const itemPagesDiscountQuantity = Math.min(
								price.extraPageCount,
								discountModel.extrapages,
							);

							let bulkDiscountPercentage = 0;
							if (bulkStateCollection) {
								const filtered = _.filter(
									bulkStateCollection,
									(bulkStateModel) => bulkStateModel.typeModel.offeringid == cartItemModel.offeringid,
								);
								if (filtered.length > 0) {
									bulkDiscountPercentage = filtered[0].quantityModel.relative / 100;
								}
							}

							const itemBaseDiscountValue = discountModel.relative / 100
						* price.costBase
						* (1 - bulkDiscountPercentage)
						* Math.min(
							cartItemModel.quantity,
							discountQuantity,
						);
							const itemPagesDiscountValue = discountModel.relative / 100 * (
								price.extraPageCount > 0
									? itemPagesDiscountQuantity / price.extraPageCount
								* (price.costPages || 0)
								* (1 - bulkDiscountPercentage)
								* Math.min(
									cartItemModel.quantity,
									discountQuantity,
								)
									: 0
							);

							const itemProductDiscountValue = itemBaseDiscountValue + itemPagesDiscountValue;

							let itemShippingDiscountValue = 0;
							const shippingModel = cartItemModel.shippingid
								? ShippingModule.getById(cartItemModel.shippingid)
								: null;
							const productModel = ProductsModule.getById(cartItemModel.productid);
							let countryModel;
							let addressModel;

							if (productModel && productModel.addressid) {
								// This product has its own shipping address
								addressModel = AddressesModule.getById(productModel.addressid);
							} else {
								// Get shipping address from order details
								const addressid = CartModule.shippingaddressid;
								if (addressid) {
									addressModel = AddressesModule.getById(addressid);
								}
							}

							if (addressModel && addressModel.country) {
								countryModel = AppDataModule.findCountryWhere({ iso: addressModel.country });
							} else if (UserModule.countryid) {
								countryModel = AppDataModule.getCountry(UserModule.countryid);
							} else {
								const countryID = UserModule.countryid;
								if (countryID) {
									countryModel = AppDataModule.getCountry(countryID);
								}
							}

							// Set shipping type to discount global setting
							let shippingType = discountModel.freeshipping ? discountModel.freeshipping : 0;

							// Check if global shipping type is overwritten by specific country setting
							const discountCountryModel = countryModel ? DiscountModule.findWhereShipping({
								countryid: countryModel.id,
							}) : undefined;
							if (discountCountryModel) {
								shippingType = discountCountryModel.shippingtype;
							}

							if (shippingType > 0 && objShippingPerItem.hasOwnProperty(cartItemModel.id)) {
								if (
									shippingType == 3
							|| (shippingType == 2 && shippingModel && shippingModel.express === 0)
							|| (shippingModel && shippingModel.tracking === 0 && shippingModel.express === 0)
								) {
									// Shipping method is included with discount, so give full discount
									itemShippingDiscountValue = objShippingPerItem[cartItemModel.id];
								} else if (shippingType == 2) {
									const objTrackingShipping = PriceCalculator.productShipping({
										productid: cartItemModel.productid,
										variantid: cartItemModel.variantid,
										tracking: 1,
										express: 0,
									});
									if (objTrackingShipping && objTrackingShipping.shippingModel) {
										itemShippingDiscountValue = Math.min(
											objShippingPerItem[cartItemModel.id],
											objTrackingShipping.shippingModel.sale,
										);
									}
								} else {
									const objStandardShipping = PriceCalculator.productShipping({
										productid: cartItemModel.productid,
										variantid: cartItemModel.variantid,
										tracking: 0,
										express: 0,
									});
									if (objStandardShipping && objStandardShipping.shippingModel) {
										itemShippingDiscountValue = Math.min(
											objShippingPerItem[cartItemModel.id],
											objStandardShipping.shippingModel.sale,
										);
									}
								}
							}
							totalShippingDiscountValue += itemShippingDiscountValue;

							let itemHandlingDiscountValue = 0;
							if (discountModel.freehandling && objHandlingPerItem.hasOwnProperty(cartItemModel.id)) {
								itemHandlingDiscountValue = objHandlingPerItem[cartItemModel.id];
							}
							totalHandlingDiscountValue += itemHandlingDiscountValue;

							if (itemProductDiscountValue > 0
						|| itemShippingDiscountValue > 0
						|| itemHandlingDiscountValue > 0
							) {
								arrItems.push({
									cartItemModel,
									pagesDiscountQuantity: itemPagesDiscountQuantity,
									baseDiscountValue: itemBaseDiscountValue,
									pagesDiscountValue: itemPagesDiscountValue,
									shippingDiscountValue: itemShippingDiscountValue,
									handlingDiscountValue: itemHandlingDiscountValue,
								});

								totalDiscountValue += itemProductDiscountValue;
								totalDiscountValue += itemShippingDiscountValue;
								totalDiscountValue += itemHandlingDiscountValue;

								// Update quantity setting for discount
								discountQuantity = Math.max(
									0,
									discountQuantity - cartItemModel.quantity,
								);
							}
						},
					);
				}
			},
		);

		return {
			discountModel,
			arrItems,
			totalShippingDiscountValue: Math.ceil(totalShippingDiscountValue),
			totalHandlingDiscountValue: Math.ceil(totalHandlingDiscountValue),
			totalDiscountValue: Math.ceil(totalDiscountValue),
		};
	}

	static getProductOffering(options: {
		cartItemModel?: DB.ShoppingCartItemModel;
		productid?: number;
		variantid?: number;
	}) {
		let groupid: DB.OfferingModel['groupid'] | undefined;
		let typeid: number | undefined;

		// For cart items we use the properties of that item
		// (instead on the input variables for this function)
		if (options.cartItemModel) {
			options.productid = options.cartItemModel.productid;
			options.variantid = options.cartItemModel.variantid;
			({ groupid, typeid } = options.cartItemModel);
		} else if (options.productid) {
			const productModel = ProductsModule.getById(options.productid);
			if (productModel) {
				groupid = productModel.group;
				({ typeid } = productModel);
				if (productModel.variantid) {
					options.variantid = productModel.variantid;
				}
			} else {
				throw new Error('Could not find required product model for calculation');
			}
		} else {
			throw new Error('Missing required parameters for calculation');
		}

		// Define offer variable
		let offer: DB.OfferingModel | undefined;

		// Get offer type by variantid (if set)
		if (options.variantid) {
			offer = AppDataModule.findOfferingWhere({
				groupid,
				typeid,
				variantid: options.variantid,
			});
			if (offer
				&& ProductStateModule.productId
				&& ProductStateModule.productId == options.productid
			) {
				const pc = ProductStateModule.getPagesQuantity;
				if (pc < offer.minpages || pc > offer.maxpages) {
					// offer no longer valid for this number of pages, so update offering
					offer = undefined;
				}
			}
		}

		// If offer type unavailable (no variantid set), find it by matching properties
		if (!offer) {
			// Get all offer types for the product's typeid
			const offerings = AppDataModule.findOffering({
				groupid,
				typeid,
			});

			let pagecount: number;
			// If the product is set as productState (the product is Work In Progress)
			// calculate the pagecount
			if (ProductStateModule.productId
				&& ProductStateModule.productId == options.productid
			) {
				const minOfferingModel = _.min(
					offerings,
					(offering) => offering.minpages,
				);
				if (typeof minOfferingModel === 'number') {
					throw new Error(`Could not find required offeringModel with groupid ${groupid} and typeid ${typeid}`);
				}

				pagecount = ProductStateModule.getPagesQuantity;
				pagecount = Math.max(
					minOfferingModel.minpages,
					pagecount,
				);
			} else if (options.cartItemModel) {
				// Otherwise get the pagecount by the cartitem's property
				pagecount = options.cartItemModel.pages;
			}

			// Find the valid offer type (first one that matches the pagecount)
			offer = _.find(
				offerings,
				(offering) => {
					const flagMinPages = pagecount >= offering.minpages;
					const flagMaxPages = pagecount <= offering.maxpages;
					return flagMinPages && flagMaxPages;
				},
			);
		}

		return offer;
	}

	static projectPrice(options: {
		cartItemModel?: DB.ShoppingCartItemModel;
		productid?: number;
		variantid?: number;
		includeHandling?: boolean;
		includeUpsell?: boolean;
		offeringid?: number;
		pages?: number;
		quantity?: number;
	}): PricingObject {
		// For cart items we use the properties of that item
		// (instead on the input variables for this function)
		if (options.cartItemModel) {
			options.productid = options.cartItemModel.productid;
			options.variantid = options.cartItemModel.variantid;
		}
		if (typeof options.includeHandling === 'undefined') {
			options.includeHandling = true;
		}
		if (typeof options.includeUpsell === 'undefined') {
			options.includeUpsell = true;
		}

		// Get offering for this product
		let offeringModel: DB.OfferingModel | undefined;

		if (options.offeringid) {
			offeringModel = AppDataModule.getOffering(options.offeringid);
		} else if (options.cartItemModel) {
			offeringModel = AppDataModule.getOffering(options.cartItemModel.offeringid);
		} else {
			offeringModel = PriceCalculator.getProductOffering(options);
		}

		if (!offeringModel) {
			throw new Error('No valid offering found while calculating product price');
		}

		let printPageCount = 0;

		if (options.pages) {
			printPageCount = options.pages;
		} else if (ProductStateModule.productId
			&& ProductStateModule.productId == options.productid
		) {
			printPageCount = ProductStateModule.getPagesQuantity;

			if (offeringModel.fixedcover) {
				// Correction for books with fixed cover (back-, and front cover are excluded)
				printPageCount -= 2;
			}

			// Add fixed pages to page count
			const extraPages = ThemeStateModule.getFixedPages;
			printPageCount += extraPages.length;
		} else if (options.cartItemModel) {
			if (options.cartItemModel.printpages) {
				printPageCount = options.cartItemModel.printpages;
			} else {
				// temporary fallback for migration
				printPageCount = options.cartItemModel.pages;
			}
		}

		if (
			offeringModel.virtual
			&& ProductStateModule.productId
			&& ProductStateModule.productId == options.productid
		) {
			const countMap: Record<DB.OfferingModel['id'], number> = {};
			ProductStateModule.getPages.forEach((pageModel) => {
				if (!pageModel.offeringId) {
					throw new Error('Missing offeringId on page in virtual offering project');
				}

				if (countMap.hasOwnProperty(pageModel.offeringId)) {
					countMap[pageModel.offeringId] += pageModel.quantity;
				} else {
					countMap[pageModel.offeringId] = pageModel.quantity;
				}
			});

			let costBase = 0;
			let costPages = 0;
			let extraPageCount = 0;
			let extraPagePrice = 0;
			let costBaseFrom = 0;
			let costPagesFrom = 0;
			let totalPageCount = 0;
			let costUpsell = 0;
			let bulkDiscountValue = 0;
			const subItems: PricingObject[] = [];

			_.keys(countMap).forEach((k) => {
				const offeringId = parseInt(
					k,
					10,
				);
				const offeringM = AppDataModule.getOffering(offeringId);

				if (!offeringM) {
					throw new Error('Could not find required offeringModel');
				}

				const productPrice = this.productPrice(
					offeringM,
					countMap[offeringId],
					{
						includeHandling: options.includeHandling,
						includeUpsell: options.includeUpsell,
					},
				);

				subItems.push(productPrice);

				costBase += productPrice.costBase;
				costPages += productPrice.costPages || 0;
				extraPageCount += productPrice.extraPageCount;
				extraPagePrice += productPrice.extraPagePrice;
				costBaseFrom += productPrice.costBaseFrom || 0;
				costPagesFrom += productPrice.costPagesFrom || 0;
				totalPageCount += productPrice.totalPageCount;
				costUpsell += productPrice.costUpsell;
				bulkDiscountValue += productPrice.bulkDiscountValue;
			});

			return {
				bulkDiscountValue,
				costBase,
				costUpsell,
				extraPageCount,
				extraPagePrice,
				offeringModel,
				printPageCount,
				subTotal: costBase + costPages,
				subTotalFrom: costBaseFrom
					? costBaseFrom + (costPagesFrom || 0)
					: null,
				totalPageCount,
				upsellCollection: [],
				subItems,
			};
		}

		return this.productPrice(
			offeringModel,
			printPageCount,
			{
				cartItemModel: options.cartItemModel,
				includeHandling: options.includeHandling,
				includeUpsell: options.includeUpsell,
				quantity: options.quantity,
			},
		);
	}

	static productPrice(
		offeringModel: DB.OfferingModel,
		printPageCount: number,
		options: {
			cartItemModel?: DB.ShoppingCartItemModel;
			includeHandling?: boolean;
			includeUpsell?: boolean;
			quantity?: number;
		},
	): PricingObject {
		if (!UserModule.currency) {
			throw new Error('Missing user currency setting');
		}

		// Make sure the minimum print page requirement is reached
		let totalPageCount = Math.max(
			offeringModel.minprintpages,
			printPageCount,
		);
		const rest = (totalPageCount - offeringModel.minprintpages + offeringModel.pageinterval) % offeringModel.pageinterval;
		totalPageCount += rest > 0 ? offeringModel.pageinterval - rest : 0;
		const extraPageCount = Math.max(
			0,
			totalPageCount - offeringModel.minprintpages,
		);

		if (!offeringModel.showPricing) {
			return {
				bulkDiscountValue: 0,
				costBase: 0,
				costUpsell: 0,
				extraPageCount,
				extraPagePrice: 0,
				handlingModel: undefined,
				offeringModel,
				printPageCount,
				subTotal: 0,
				subTotalFrom: 0,
				totalPageCount,
				upsellCollection: [],
			};
		}

		// Get the right pricing model
		const pricingModel = AppDataModule.findPricingWhere({
			offeringid: offeringModel.id,
			currency: UserModule.currency,
		});

		// If no pricing model found, throw error for debugging
		if (!pricingModel) {
			throw new Error(`No pricing found for offering ${offeringModel.id} and currency ${UserModule.currency}`);
		}

		const costBase = pricingModel.price_base;
		const costBaseFrom = pricingModel.price_base_from != pricingModel.price_base
			? pricingModel.price_base_from
			: null;
		let costPages = 0;
		let costPagesFrom = null;
		let costUpsell = 0;
		let costHandling = 0;

		const extraPagePrice = Math.ceil(pricingModel.price_page * offeringModel.pageinterval) / offeringModel.pageinterval;

		if (extraPageCount > 0) {
			costPages = extraPageCount * extraPagePrice;

			if (costBaseFrom) {
				const pagePriceFrom = Math.ceil(pricingModel.price_page_from * offeringModel.pageinterval) / offeringModel.pageinterval;
				costPagesFrom = extraPageCount * pagePriceFrom;
			}
		}

		let handlingModel;
		if (options.includeHandling) {
			handlingModel = AppDataModule.findHandlingWhere({
				offeringid: offeringModel.id,
				currency: UserModule.currency,
			});

			if (handlingModel) {
				if (handlingModel.scope == 'quantity' && options.cartItemModel) {
					costHandling += handlingModel.price * options.cartItemModel.quantity;
				} else if (handlingModel.scope == 'quantity' || handlingModel.scope == 'item') {
					costHandling += handlingModel.price;
				}
			}
		}

		if (options.includeUpsell && options.cartItemModel) {
			const relatedModels = CartItemsModule.where({
				shoppingcartitemid: options.cartItemModel.id,
			});

			relatedModels.forEach((cartItemModel) => {
				const objUpsellPrice = PriceCalculator.projectPrice({
					cartItemModel,
				});
				costUpsell += objUpsellPrice.subTotal * cartItemModel.quantity;
			});
		}

		let bulkDiscountValue = 0;
		let bulkModel: DB.BulkModel | undefined;
		let bulkQuantityModels: DB.BulkQuantityModel[] | undefined;

		const bulkProductTypeModel = AppDataModule.bulkproducttypes.find(
			(model) => model.offeringid == offeringModel.id,
		);

		if (bulkProductTypeModel) {
			bulkModel = AppDataModule.bulks.find(
				(model) => model.id === bulkProductTypeModel.bulkid,
			);

			const itemCount = options.cartItemModel
				? options.cartItemModel.quantity
				: (options.quantity || 1);

			if (bulkModel) {
				const packageSize = OfferingCountForBulkDiscount(
					bulkModel,
					offeringModel,
					totalPageCount,
					itemCount,
				);

				bulkQuantityModels = AppDataModule.bulkquantities.filter(
					(m) => m.bulkid === bulkModel?.id,
				);

				const bulkQuantityModel = bulkQuantityModels.find(
					(m) => m.bulkid === bulkProductTypeModel.bulkid
						&& packageSize >= m.from
						&& packageSize <= m.to,
				);
				if (bulkQuantityModel) {
					if (bulkQuantityModel.relative > 0) {
						bulkDiscountValue = (costBase + costPages) * (bulkQuantityModel.relative / 100);
					} else if (bulkQuantityModel.absolute > 0) {
						bulkDiscountValue = bulkQuantityModel.absolute;
					} else if (bulkQuantityModel.absolute_q > 0) {
						bulkDiscountValue = bulkQuantityModel.absolute_q * (packageSize / itemCount);
					}
				}
			}
		}

		const returnObject: PricingObject = {
			bulkDiscountValue,
			costBase,
			costUpsell,
			extraPageCount,
			extraPagePrice,
			handlingModel,
			offeringModel,
			printPageCount,
			subTotal: costBase + costPages,
			subTotalFrom: costBaseFrom
				? costBaseFrom + (costPagesFrom || 0)
				: null,
			totalPageCount,
			upsellCollection: [],

			// Currently unused return properties
			costBaseFrom,
			costPages,
			costPagesFrom,
			costHandling,
		};

		if (bulkModel && bulkQuantityModels) {
			returnObject.bulkDiscount = {
				bulkModel,
				bulkQuantityModels,
			};
		}

		return returnObject;
	}

	static productShipping(options: {
		cartItemModel?: DB.ShoppingCartItemModel;
		productid?: number;
		variantid?: number;
		tracking?: 0|1;
		express?: 0|1;
	} = {}) {
		// Define variables
		let arrShippingModels: DB.ShippingModel[] = [];
		let shippingModel: DB.ShippingModel | undefined;
		let price = 0;
		const filterParams: {
			offeringid?: number;
			tracking?: 0|1;
			express?: 0|1;
			currency?: string;
			countrycode?: string;
		} = {};
		let countryModel: DB.CountryModel | undefined;
		let addressModel: DB.AddressModel | undefined;

		// For cart items we use the properties of that item (instead on the input variables for this function)
		if (options.cartItemModel) {
			options.productid = options.cartItemModel.productid;
			options.variantid = options.cartItemModel.variantid;

			if (options.cartItemModel.shippingid
				&& options.tracking === undefined
				&& options.express === undefined
			) {
				shippingModel = ShippingModule.getById(options.cartItemModel.shippingid);
			}
		}

		// Get viable offering for this product
		const offeringModel = PriceCalculator.getProductOffering(options);

		if (!offeringModel) {
			throw new Error('No valid offering found while calculating product price');
		}

		if (!shippingModel) {
			filterParams.offeringid = offeringModel.id;

			if (options.tracking !== undefined) {
				filterParams.tracking = options.tracking;
			}
			if (options.express !== undefined) {
				filterParams.express = options.express;
			}

			if (ProductStateModule.getAddress) {
				addressModel = ProductStateModule.getAddress;

				if (!addressModel.country || addressModel.country.length === 0) {
					const countryId = UserModule.countryid;
					if (countryId) {
						countryModel = AppDataModule.getCountry(countryId);
						if (countryModel) {
							ProductStateModule.changeAddress({
								country: countryModel.iso,
							});
						}
					}
				} else {
					countryModel = AppDataModule.findCountryWhere({ iso: addressModel.country });
				}

				if (!UserModule.currency || !addressModel.country) {
					throw new Error('Could not calculate shipping price');
				}

				filterParams.currency = UserModule.currency;
				filterParams.countrycode = addressModel.country;
			} else {
				const addressid = CartModule.shippingaddressid;
				if (addressid) {
					addressModel = AddressesModule.getById(addressid);
				}

				if (addressModel && addressModel.country && addressModel.country.length) {
					countryModel = AppDataModule.findCountryWhere({ iso: addressModel.country });
				} else if (UserModule.countryid) {
					countryModel = AppDataModule.getCountry(UserModule.countryid);
				}

				if (!UserModule.currency) {
					throw new Error('Could not calculate shipping price');
				}

				filterParams.currency = UserModule.currency;

				if (countryModel && countryModel.regionid && AppDataModule.findRegionOfferingLinkWhere({
					offeringid: offeringModel.id,
					regionid: countryModel.regionid,
				})) {
					filterParams.countrycode = countryModel.iso;
				}
			}

			// Find best fit shipping data with offering id
			let strOfferingId = `${offeringModel.id}`;
			while (arrShippingModels.length === 0 && strOfferingId.length) {
				filterParams.offeringid = parseInt(
					strOfferingId,
					10,
				);
				arrShippingModels = ShippingModule.where(filterParams);
				if (arrShippingModels.length === 0) {
					strOfferingId = strOfferingId.substring(
						0,
						strOfferingId.length - 1,
					);
				}
			}

			if (arrShippingModels.length === 0) {
				// Try and find shipping data for global country setting
				filterParams.countrycode = '';
				strOfferingId = `${offeringModel.id}`;
				while (arrShippingModels.length === 0 && strOfferingId.length) {
					filterParams.offeringid = parseInt(
						strOfferingId,
						10,
					);
					arrShippingModels = ShippingModule.where(filterParams);
					if (arrShippingModels.length === 0) {
						strOfferingId = strOfferingId.substring(
							0,
							strOfferingId.length - 1,
						);
					}
				}
			}

			if (arrShippingModels && arrShippingModels.length === 1) {
				([shippingModel] = arrShippingModels);
			} else if (arrShippingModels && arrShippingModels.length > 1) {
				// Get the cheapest option as the default shipping model
				_.each(
					arrShippingModels,
					(shippingOption) => {
						if (!shippingModel || shippingOption.sale < shippingModel.sale) {
							shippingModel = shippingOption;
						}
					},
				);
			}
		} else if (addressModel && addressModel.country && addressModel.country.length) {
			countryModel = AppDataModule.findCountryWhere({ iso: addressModel.country });
		} else if (UserModule.countryid) {
			countryModel = AppDataModule.getCountry(UserModule.countryid);
		}

		if (shippingModel) {
			if (shippingModel.scope == 'quantity' && options.cartItemModel) {
				price = shippingModel.sale * options.cartItemModel.quantity;
			} else if (shippingModel.scope == 'quantity' || shippingModel.scope == 'item') {
				price = shippingModel.sale;
			}
		}

		const deliveryEstimate = countryModel && shippingModel && shippingModel.days_min && shippingModel.days_max ? {
			min: compileBusinessDays(
				null,
				shippingModel.days_min,
				countryModel.iso,
				'MMMM D',
			),
			max: compileBusinessDays(
				null,
				shippingModel.days_max,
				countryModel.iso,
				'MMMM D',
			),
		} : null;

		return {
			arrShippingModels,
			countryModel,
			shippingModel,
			price,
			deliveryEstimate,
		};
	}
}
