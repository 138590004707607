import './defines';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import {
	email,
	max,
	required,
} from '@vee-validate/rules';
import zipCodeValidation from 'components/vee-validation/zipcode';
import * as DB from 'interfaces/database';
import {
	AppDataModule,
	ConfigModule,
	ProductStateModule,
} from 'store';
import filterText from 'tools/filter-text';
import _ from 'underscore';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	defineRule,
	Field,
	Form,
} from 'vee-validate';
import {
	Prop,
	Ref,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

defineRule(
	'zipcode',
	zipCodeValidation,
);
defineRule(
	'required',
	required,
);
defineRule(
	'email',
	email,
);
defineRule(
	'max',
	max,
);
localize({
	en,
	es,
	fr,
	nl,
});

interface countryData {
	iso: string;
	name: string;
}

@Component({
	components: {
		Field,
		Form,
	},
	emits: ['closeDialog'],
	mixins: [Template],
})
class EditAddressView extends Vue {
	@Prop({
		required: true,
		schema: 'ProductModel',
		type: Object,
	})
	public readonly productModel!: DB.ProductModel;

	private get addressModel() {
		return ProductStateModule.getAddress;
	}

	protected get addressCity() {
		return this.addressModel
			? this.addressModel.city
			: '';
	}

	protected set addressCity(value) {
		ProductStateModule.changeAddress({
			city: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressCountry() {
		return this.addressModel
			? this.addressModel.country
			: '';
	}

	protected set addressCountry(value) {
		ProductStateModule.changeAddress({
			country: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressName() {
		return this.addressModel
			? this.addressModel.name
			: '';
	}

	protected set addressName(value) {
		ProductStateModule.changeAddress({
			name: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressState() {
		return this.addressModel
			? this.addressModel.state
			: '';
	}

	protected set addressState(value) {
		ProductStateModule.changeAddress({
			state: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressStreet1() {
		return this.addressModel
			? this.addressModel.address1
			: '';
	}

	protected set addressStreet1(value) {
		ProductStateModule.changeAddress({
			address1: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressStreet2() {
		return this.addressModel
			? this.addressModel.address2
			: '';
	}

	protected set addressStreet2(value) {
		ProductStateModule.changeAddress({
			address2: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get addressZipCode() {
		return this.addressModel
			? this.addressModel.zipcode
			: '';
	}

	protected set addressZipCode(value) {
		ProductStateModule.changeAddress({
			zipcode: value ? filterText(
				value,
				ConfigModule.textEncodingSupport,
			) : null,
		});
	}

	protected get countryStateprovs() {
		if (this.addressModel && this.addressModel.country) {
			const countryModel = AppDataModule.findCountryWhere({
				iso: this.addressModel.country,
			});
			if (countryModel) {
				return AppDataModule.findStateProv({ countryid: countryModel.id });
			}
		}

		return [];
	}

	protected get countries() {
		const groupid = this.productModel.group;
		const { typeid } = this.productModel;
		const countries: countryData[] = [];

		// Get all countries that have access to this product, and can thus be a destination to send the card to
		const regionOfferingModels = AppDataModule.findRegionOfferingLink({
			groupid,
			typeid,
		});
		regionOfferingModels.forEach((regionOfferingModel) => {
			AppDataModule.countries.forEach((countryModel) => {
				if (countryModel.regionid == regionOfferingModel.regionid) {
					countries.push({
						iso: countryModel.iso.trim(),
						name: AppDataModule.getCountryName(countryModel.id),
					});
				}
			});
		});

		return _.sortBy(
			countries,
			'name',
		);
	}

	@Ref('observer')
	private readonly observer!: InstanceType<typeof Form>;

	@Ref('nameInput')
	private readonly nameInput?: HTMLInputElement;

	protected mounted() {
		localize(window.locale);
		this.autoFocus();
	}

	private autoFocus() {
		if (this.nameInput) {
			const inputField = this.nameInput;

			// Set focus to text field
			inputField.focus();

			if (
				this.addressModel
				&& this.addressModel.name
				&& this.addressModel.name.length
			) {
				// Set cursor at the end of the text
				inputField.setSelectionRange(
					this.addressModel.name.length,
					this.addressModel.name.length,
				);
			}
		}
	}

	protected save() {
		this.observer
			.validate()
			.then((isValid) => {
				if (isValid) {
					this.$emit('closeDialog');
				} else {
					// do stuff if not valid.
				}
			});
	}
}

export default toNative(EditAddressView);
