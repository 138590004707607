import './defines';
import { Model } from 'utils/decorators';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'SwitchBoxComponent',
	emits: ['click'],
	mixins: [Template],
})
class SwitchBoxComponent extends Vue {
	@Model({
		acceptedValues: [
			true,
			false,
		],
		description: 'Defines the value of the switch box',
		default: false,
		type: Boolean,
	})
	public readonly value!: boolean;

	protected internalValue = false;

	@Watch('internalValue')
	protected onInternalValueChange(): void {
		if (this.value !== this.internalValue) {
			this.$emit(
				'update:value',
				this.internalValue,
			);
		}
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		if (this.internalValue !== this.value) {
			this.internalValue = this.value;
		}
	}

	protected onClick(event: MouseEvent): void {
		this.internalValue = !this.internalValue;
		this.$emit(
			'click',
			event,
		);
	}
}

export default toNative(SwitchBoxComponent);
