import './defines';
import PriceCalculator from 'classes/price-calculator';
import User from 'classes/user';
import { CartItemUpsellModel } from 'interfaces/app';
import * as DB from 'interfaces/database';
import getDeviceDetails from 'ops/device-details';
import { AppDataModule, CartItemsModule, UserModule } from 'store';
import formatMoney from 'tools/format-money';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import UpsellSelectItemView from 'views/upsell-select-item';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		UpsellSelectItemView,
	},
	mixins: [Template],
})
class UpsellSelectView extends Vue {
	@Prop({
		required: true,
		type: Number,
	})
	public readonly cartItemId!: number;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly offeringId!: number;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly productId!: number;

	protected quantity = 0;

	protected get upsellModels(): DB.UpsellModel[] {
		return AppDataModule.whereUpsell({
			offeringid: this.offeringId,
			autoinclude: 0,
		});
	}

	protected cartItemModel(): DB.ShoppingCartItemModel | undefined {
		return CartItemsModule.getById(this.cartItemId);
	}

	protected priceLabel(upsellModel: DB.UpsellModel): string {
		const currencyModel = UserModule.currency
			? AppDataModule.getCurrency(UserModule.currency)
			: undefined;
		const prefix = currencyModel && currencyModel.prefix ? currencyModel.prefix : '';
		const suffix = currencyModel && currencyModel.suffix ? currencyModel.suffix : '';
		const separator = currencyModel && currencyModel.separator ? currencyModel.separator : '.';
		const price = PriceCalculator.projectPrice({
			productid: this.productId,
			offeringid: upsellModel.upsellid,
		});
		return prefix + formatMoney(
			price.subTotal,
			separator,
		) + suffix;
	}

	protected findCartItemUpsell(cartItemId: number, upsellId: number): void {
		const cartItemUpsellModel = CartItemsModule.findWhere({
			shoppingcartitemid: cartItemId,
			offeringid: upsellId,
		});
		this.quantity = cartItemUpsellModel ? cartItemUpsellModel.quantity : 0;
	}

	protected handleCartItemUpsell(
		{
			cartItemModel, upsellModel, quantity,
		}: CartItemUpsellModel,
	): void {
		const cartItemUpsellModel = CartItemsModule.findWhere({
			shoppingcartitemid: cartItemModel.id,
			offeringid: upsellModel.upsellid,
		});

		if (cartItemUpsellModel) {
			if (quantity > 0) {
				CartItemsModule.putModel({
					id: cartItemUpsellModel.id,
					data: {
						quantity,
					},
				});
			} else {
				CartItemsModule.destroyModel({
					id: cartItemUpsellModel.id,
				});
			}
		} else if (quantity > 0) {
			const strOfferingId = `${upsellModel.upsellid}`;
			const groupid = parseInt(
				strOfferingId.substring(
					0,
					3,
				),
				10,
			) as DB.ShoppingCartItemModel['groupid'];
			const typeid = parseInt(
				strOfferingId.substring(
					3,
					7,
				),
				10,
			);
			const variantid = parseInt(
				strOfferingId.substring(7),
				10,
			);

			const data = JSON.parse(
				JSON.stringify(cartItemModel),
			) as Partial<DB.ShoppingCartItemModel>;
			delete data.id;
			data.shoppingcartitemid = cartItemModel.id;
			data.offeringid = upsellModel.upsellid;
			data.quantity = quantity;
			data.groupid = groupid;
			data.typeid = typeid;
			data.variantid = variantid;

			User.saveCart()
				.then(() => getDeviceDetails())
				.then((deviceDetails) => {
					data.platform = deviceDetails.platform;

					return CartItemsModule.createModels({
						data: [data],
					});
				});
		}
	}
}

export default toNative(UpsellSelectView);
