import PriceCalculator from 'classes/price-calculator';
import ProductState from 'classes/productstate';
import User from 'classes/user';
import EventBus from 'components/event-bus';
import analytics from 'controllers/analytics';
import navigate from 'controllers/navigate';
import merge from 'deepmerge';
import { PricingObject } from 'interfaces/app';
import * as DB from 'interfaces/database';
import getDeviceDetails from 'ops/device-details';
import * as DialogService from 'services/dialog';
import { ERRORS_OFFLINE } from 'settings/errors';
import { OfferingGroups } from 'settings/offerings';
import {
	AppDataModule,
	AppStateModule,
	CartItemsModule,
	CartModule,
	ConfigModule,
	ProductStateModule,
	UserModule,
} from 'store';
import _ from 'underscore';
// import BulkDiscountSelectView from 'views/bulk-discount-offer/template.vue';
import EditAddressView from 'views/edit-address';
import OfferingUpgradeView from 'views/offering-upgrade';
import OfferingVariantSelectView from 'views/offering-variant-select';
import PostcardAddressView from 'views/postcard-address';
import UpsellSelectView from 'views/upsell-select';
import showSettings from './show-settings';

interface AddProjectToCartOptions {
	force?: boolean;
	productid: number;
	variantid?: number;
	showCancel?: boolean;
	quantity?: number;
	shippingDate?: number;
}

/**
 * @description Check if there are multiple versions of the offering available
 * If there are: let the user select one in a dialog screen
 */
const selectItemOffering = (
	productModel: DB.ProductModel,
	options: Pick<AddProjectToCartOptions, 'variantid'> = {},
): Promise<DB.OfferingModel> => {
	// Add defaults to options
	const defaults = {};
	options = options ? merge(
		defaults,
		options,
	) : defaults;

	const searchProps: Partial<DB.OfferingModel> = {
		groupid: productModel.group,
		typeid: productModel.typeid,
	};
	if (productModel.variantid) {
		searchProps.variantid = productModel.variantid;
	} else {
		// This should soon be deprecated
		// Once the offering options have been configured for all labels, we
		// no longer have a need for the offeringVariantSelect dialog
		const [firstVariant] = AppDataModule.findOffering({
			...searchProps,
			variantid: 1,
		});
		if (firstVariant) {
			if (AppDataModule.getOfferingOptionModels(firstVariant.id).length) {
				// The offering has offering options configured, so we don't need the legacy offering variant select dialog
				searchProps.variantid = 1;
			} else if (firstVariant.flexgroupid) {
				// We limit the options to offerings in the same flex group
				searchProps.flexgroupid = firstVariant.flexgroupid;
			}
		}
	}
	const offeringTypes = AppDataModule.findOffering(searchProps);

	const minOfferingModel = _.min(
		offeringTypes,
		(offering) => offering.minpages,
	);
	if (typeof minOfferingModel === 'number') {
		throw new Error(`Could not find required offeringModel with groupid ${productModel.group} and typeid ${productModel.typeid}`);
	}

	const pagecount = Math.max(
		minOfferingModel.minpages,
		ProductStateModule.getPagesQuantity,
	);
	const countryModel = UserModule.countryid
		? AppDataModule.getCountry(UserModule.countryid)
		: undefined;

	const offerings = offeringTypes.filter(
		(offering) => pagecount >= offering.minpages
			&& pagecount <= offering.maxpages
			&& (!options.variantid || (offering.variantid == options.variantid))
			&& countryModel
			&& AppDataModule.findRegionOfferingLinkWhere({
				offeringid: offering.id,
				regionid: countryModel.regionid,
			}),
	);

	if (offerings.length === 0) {
		const err = new Error(
			window.App.router.$i18next.t('offeringNotInStock'),
		);

		if (typeof window.glBugsnagClient !== 'undefined') {
			window.glBugsnagClient.notify(
				err,
				(event) => {
					event.severity = 'warning';
					event.addMetadata(
						'component',
						_.extend(
							options,
							{
								pagecount,
							},
						),
					);
				},
			);
		}

		return Promise.reject(err);
	}

	if (offerings.length > 1) {
		return new Promise((resolve) => {
			// Show offering version selection dialog
			const { close: closeDialog } = DialogService.openDialog({
				header: {
					title: window.App.router.$t('dialogHeaderOfferingVersion'),
				},
				body: {
					component: OfferingVariantSelectView,
					props: {
						offerings,
						productModel,
					},
					listeners: {
						closeDialog: () => {
							closeDialog();
						},
						select: (event: ServiceEvent<DB.OfferingModel>) => {
							resolve(event.payload);
						},
					},
				},
				width: 400,
			});
		});
	}
	const offeringModel = offerings[0];

	const offeringUpgradeGroup: DB.OfferingModel[] = [
		offeringModel,
	];

	// ================ Start of "hacked" code block
	// This feature is "hacked" to provide a specific upgrade from a Hema fastbooklet 10x10 to a 13x13
	// The feature will have to be refactored to handle dynamic product configurations

	if (
		offeringModel.groupid == 101
		&& offeringModel.typeid == 1013
	) {
		const bigger = _.findWhere(
			AppDataModule.offerings,
			{ id: offeringModel.variantid === 1 ? 10110021 : 10110022 },
		);

		if (bigger) {
			offeringUpgradeGroup.push(
				bigger,
			);
		}
	}

	// ================ End of specific "hacked" code block

	if (ConfigModule['features.upsell']
		&& OfferingGroups(
			offeringModel.groupid,
			['WallDecoration'],
		)
	) {
		const ratio = (offeringModel.width + 2 * offeringModel.bleedmargin) / (offeringModel.height + 2 * offeringModel.bleedmargin);
		const pixels = offeringModel.width * offeringModel.height;

		const biggerWithSameRatio = AppDataModule.offerings.filter((m) => {
			if (!m.applyUpscaling) {
				// Only show offerings that have upscaling enabled in their configuration settings
				return false;
			}

			if (m.flexgroupid != offeringModel.flexgroupid) {
				// Only show upsell suggestions for offerings in the same flex group
				return false;
			}

			if (m.variantid != offeringModel.variantid) {
				// Only show upsell suggestions for offerings with the same variantid
				return false;
			}

			if (m.width * m.height <= pixels * 1.05) {
				// Upsell suggestions should be at least 5% bigger in size
				return false;
			}

			if (!m.instock) {
				// Only show offerings that are in stock
				return false;
			}

			if (countryModel) {
				const isAvailableInRegion = AppDataModule.findRegionOfferingLinkWhere({
					offeringid: offeringModel.id,
					regionid: countryModel.regionid,
				});
				if (!isAvailableInRegion) {
					// Only show offerings that are available in the current region
					return false;
				}
			}

			const rm = (m.width + 2 * m.bleedmargin) / (m.height + 2 * m.bleedmargin);

			// We compare with a precision of two decimals
			// (due to rounding when converting metric units to pixels, it's usually not exactly the same)
			return rm.toFixed(2) === ratio.toFixed(2);
		});

		if (biggerWithSameRatio.length) {
			const biggerWithSameRatioSorted = biggerWithSameRatio.sort(
				(a, b) => a.width * a.height - b.width * b.height,
			);

			biggerWithSameRatioSorted.some((m) => {
				if (offeringUpgradeGroup.length >= 3) {
					// The upgrade group is big enough, so stop iterating through this loop
					return true;
				}

				offeringUpgradeGroup.push(
					m,
				);

				// Return false to move to the next item in the loop
				return false;
			});
		}
	}

	if (offeringUpgradeGroup.length <= 1) {
		return Promise.resolve(offeringModel);
	}

	return new Promise((resolve) => {
		// Product needs to be saved to server so that product image can be displayed
		EventBus.once(
			'product:save:finished',
			(err: Error) => {
				DialogService.closeLoaderDialog();

				if (err) {
					resolve(offeringModel);
				} else {
					analytics.trackEvent(
						'Show upgrade dialog',
						{
							offeringid: offeringModel.id,
							groupid: offeringModel.groupid,
							choices: offeringUpgradeGroup.length,
						},
					);

					const { close: closeDialog } = DialogService.openDialog({
						header: {
							title: window.App.router.$t('dialogHeaderOfferingUpgrade'),
						},
						body: {
							component: OfferingUpgradeView,
							props: {
								offerings: offeringUpgradeGroup,
								productModel,
							},
							listeners: {
								closeDialog: () => {
									closeDialog();
								},
								select: (event: ServiceEvent<DB.OfferingModel>) => {
									const isBigger = (event.payload.width * event.payload.height) > (offeringModel.width * offeringModel.height);
									analytics.trackEvent(
										'Select upgrade offering',
										{
											offeringid: event.payload.id,
											groupid: event.payload.groupid,
											bigger: isBigger,
										},
									);

									if (
										event.payload.id !== offeringModel.id
										&& OfferingGroups(
											event.payload.groupid,
											['BasicProducts'],
										)
									) {
										const closeLoader = DialogService.openLoaderDialog();

										ProductState
											.changeOffering(event.payload.id)
											.then(() => resolve(event.payload))
											.catch(async (error) => {
												await new Promise<void>((bugsnagResolve) => {
													if (typeof window.glBugsnagClient !== 'undefined') {
														window.glBugsnagClient.notify(
															error,
															(bugsnagEvent) => {
																bugsnagEvent.severity = 'warning';
																bugsnagEvent.addMetadata(
																	'operationData',
																	{
																		productModel,
																		options,
																		offeringid: event.payload.id,
																		groupid: event.payload.groupid,
																		bigger: isBigger,
																	},
																);
															},
															bugsnagResolve,
														);
														return;
													}

													bugsnagResolve();
												});

												throw error;
											})
											.finally(closeLoader);
									} else {
										resolve(event.payload);
									}
								},
							},
						},
						width: 500,
					});
				}
			},
		);

		DialogService.openLoaderDialog();
		ProductState.save();
	});
};

const selectUpsellItems = (cartItemModel: DB.ShoppingCartItemModel): Promise<void> => new Promise((resolve) => {
	const countryModel = UserModule.countryid
		? AppDataModule.getCountry(UserModule.countryid)
		: undefined;
	if (!countryModel) {
		throw new Error('Missing required country model');
	}

	// Get all possible upsell items for this offering
	const upsellItems = AppDataModule.whereUpsell({
		offeringid: cartItemModel.offeringid,
		autoinclude: 0,
	}).filter((upsellModel) => {
		const offeringModel = AppDataModule.getOffering(upsellModel.upsellid);
		if (!offeringModel || !offeringModel.instock) {
			return false;
		}

		const isAvailableInRegion = AppDataModule.findRegionOfferingLinkWhere({
			offeringid: offeringModel.id,
			regionid: countryModel.regionid,
		});

		return Boolean(isAvailableInRegion);
	});

	if (upsellItems.length > 0) {
		const { close: closeDialog } = DialogService.openDialog({
			header: {
				hasCloseButton: false,
				title: window.App.router.$t('dialogHeaderUpsell'),
			},
			body: {
				component: UpsellSelectView,
				props: {
					cartItemId: cartItemModel.id,
					offeringId: cartItemModel.offeringid,
					productId: cartItemModel.productid,
				},
			},
			footer: {
				buttons: [
					{
						id: 'accept',
						text: window.App.router.$t('dialogButtonUpsellOk'),
						click: () => {
							closeDialog();
						},
					},
				],
			},
			listeners: {
				close: () => {
					resolve();
				},
			},
			width: 500,
		});
	} else {
		resolve();
	}
});

/**
 * @description Check for uncommon or illegal properties of item to be added to cart
 * - For books: check pagecount
 * - For cards: check if user needs to select type of card (set/single) or fill in address
 */
const checkProjectToCart = (
	productModel: DB.ProductModel,
	options: Pick<AddProjectToCartOptions, 'force' | 'variantid'> = {},
): Promise<void> => new Promise((resolve, reject) => {
	if (
		OfferingGroups(
			productModel.group,
			['BookTypes'],
		)
	) {
		if (
			!options.force
			&& productModel.userid == UserModule.id
		) {
			const projectInsights = ProductState.projectContentInsights();

			if (
				projectInsights.totalEmptyPages > 0
				&& projectInsights.totalEmptyPages == projectInsights.totalEditablePages
			) {
				// If all pages are empty, show emptybook dialog
				const closeConfirm = DialogService.openConfirmDialog({
					header: {
						title: window.App.router.$t('dialogs.emptyBook.title'),
					},
					body: {
						content: window.App.router.$t('dialogs.emptyBook.message'),
					},
					footer: {
						buttons: [
							{
								id: 'cancel',
								text: window.App.router.$t('dialogs.emptyBook.buttons.cancel'),
								click: () => {
									window.App.router.openProduct(
										productModel.id,
										false,
									);
									reject();
									closeConfirm();
								},
							},
							{
								id: 'accept',
								text: window.App.router.$t('dialogs.emptyBook.buttons.accept'),
								click: () => {
									checkProjectToCart(
										productModel,
										{
											force: true,
										},
									).then(
										resolve,
										reject,
									);
									closeConfirm();
								},
							},
						],
					},
				});
			} else if (
				projectInsights.totalEmptyPages > 0
				&& projectInsights.totalEmptyPages >= 0.5 * projectInsights.totalEditablePages
			) {
				// Half empty book, show message
				const closeConfirm = DialogService.openConfirmDialog({
					header: {
						title: window.App.router.$t('dialogs.halfEmptyBook.title'),
					},
					body: {
						content: window.App.router.$t('dialogs.halfEmptyBook.message'),
					},
					footer: {
						buttons: [
							{
								id: 'cancel',
								text: window.App.router.$t('dialogs.halfEmptyBook.buttons.cancel'),
								click: () => {
									window.App.router.openProduct(
										productModel.id,
										false,
									);
									reject();
									closeConfirm();
								},
							},
							{
								id: 'accept',
								text: window.App.router.$t('dialogs.halfEmptyBook.buttons.accept'),
								click: () => {
									checkProjectToCart(
										productModel,
										{
											force: true,
										},
									).then(
										resolve,
										reject,
									);
									closeConfirm();
								},
							},
						],
					},
				});
			} else {
				resolve();
			}
		} else {
			resolve();
		}
	} else if (
		OfferingGroups(
			productModel.group,
			['Cards'],
		)
	) {
		// Check if there are multiple versions available
		const offerings = AppDataModule.findOffering({ groupid: productModel.group, typeid: productModel.typeid });
		const sets = _.filter(
			offerings,
			(offering) => !!(offering.set && offering.set > 0),
		);
		const singles = _.filter(
			offerings,
			(offering) => offering.set === 0,
		);

		if (
			!options.hasOwnProperty('variantid')
			&& sets.length
			&& singles.length
		) {
			// Let user select to order card as set or send directly as single
			const closeConfirm = DialogService.openConfirmDialog({
				header: {
					title: window.App.router.$t('dialogHeaderCardOrder'),
				},
				body: {
					content: window.App.router.$t('dialogTextCardOrder'),
				},
				footer: {
					buttons: [
						{
							id: 'single',
							text: window.App.router.$t('dialogButtonCardOrderSingle'),
							click: () => {
								options.variantid = singles[0].variantid;
								checkProjectToCart(
									productModel,
									options,
								).then(
									resolve,
									reject,
								);
								closeConfirm();
							},
						},
						{
							id: 'set',
							text: window.App.router.$t('dialogButtonCardOrderSet'),
							click: () => {
								options.variantid = sets[0].variantid;
								checkProjectToCart(
									productModel,
									options,
								).then(
									resolve,
									reject,
								);
								closeConfirm();
							},
						},
					],
				},
			});
		} else if (
			sets.length === 0
			|| (
				singles.length > 0
				&& options.variantid == singles[0].variantid
			)
		) {
			const addressModel = ProductStateModule.getAddress;

			if (!addressModel) {
				ProductStateModule
					.addAddress()
					.then(() => {
						checkProjectToCart(
							productModel,
							options,
						).then(
							resolve,
							reject,
						);
					});
			} else if (
				(
					!addressModel.address1
					&& !addressModel.address2
				)
				|| !addressModel.zipcode
				|| !addressModel.city
			) {
				// Invalid address, show message
				const closeAlert = DialogService.openAlertDialog({
					header: {
						title: window.App.router.$t('dialogHeaderInvalidAddress'),
					},
					body: {
						content: window.App.router.$t('dialogTextInvalidAddress'),
					},
					footer: {
						buttons: [
							{
								id: 'accept',
								text: window.App.router.$t('dialogButtonInvalidAddressOk'),
								click: () => {
									const { close: closeDialog } = DialogService.openDialog({
										header: {
											title: window.App.router.$t('dialogHeaderCardSingle'),
										},
										body: {
											component: EditAddressView,
											props: {
												productModel,
											},
											listeners: {
												closeDialog: () => {
													closeDialog();
												},
											},
										},
										width: 400,
										listeners: {
											close: () => {
												if (
													(addressModel.address1 || addressModel.address2)
													&& addressModel.zipcode
													&& addressModel.city
												) {
													resolve();
												} else {
													reject();
												}
											},
										},
									});
									closeAlert();
								},
							},
						],
					},
				});
			} else {
				// Have user confirm the shipping address of the card
				const { close: closeDialog } = DialogService.openDialog({
					header: {
						hasCloseButton: false,
						title: window.App.router.$t('dialogHeaderConfirmPostcardAddress'),
					},
					body: {
						component: PostcardAddressView,
						props: {
							productModel,
						},
					},
					footer: {
						buttons: [
							{
								id: 'cancel',
								text: window.App.router.$t('buttonCardEditAddress'),
								click: () => {
									closeDialog();
									const { close: closeEditAddressDialog } = DialogService.openDialog({
										header: {
											title: window.App.router.$t('dialogHeaderCardSingle'),
										},
										body: {
											component: EditAddressView,
											props: {
												productModel,
											},
											listeners: {
												closeDialog: () => {
													closeEditAddressDialog();
												},
											},
										},
										listeners: {
											close: () => {
												resolve();
											},
										},
										width: 400,
									});
								},
							},
							{
								id: 'accept',
								text: window.App.router.$t('dialogButtonOk'),
								click: () => {
									closeDialog();
									resolve();
								},
							},
						],
					},
					width: 400,
				});
			}
		} else {
			resolve();
		}
	} else if (
		OfferingGroups(
			productModel.group,
			['WallDecoration'],
		)
	) {
		if (!options.force) {
			const projectInsights = ProductState.projectContentInsights();
			if (projectInsights.totalEmptyPages === projectInsights.totalEditablePages) {
				// The wall decoration product is empty, show warning
				const closeConfirm = DialogService.openConfirmDialog({
					header: {
						title: window.App.router.$t('dialogs.emptyWallDecoProject.title'),
					},
					body: {
						content: window.App.router.$t('dialogs.emptyWallDecoProject.message'),
					},
					footer: {
						buttons: [
							{
								id: 'cancel',
								text: window.App.router.$t('dialogs.emptyWallDecoProject.buttons.cancel'),
								click: () => {
									window.App.router.openProduct(
										productModel.id,
										false,
									);
									reject();
									closeConfirm();
								},
							},
							{
								id: 'accept',
								text: window.App.router.$t('dialogs.emptyWallDecoProject.buttons.accept'),
								click: () => {
									checkProjectToCart(
										productModel,
										{
											force: true,
										},
									).then(
										resolve,
										reject,
									);
									closeConfirm();
								},
							},
						],
					},
				});
			} else {
				resolve();
			}
		} else {
			resolve();
		}
	} else {
		resolve();
	}
});

const pricingSuggestions = (
	productModel: DB.ProductModel,
	offeringModel: DB.OfferingModel,
	options: Pick<AddProjectToCartOptions, 'force' | 'quantity'> = {},
): Promise<{ offeringModel: DB.OfferingModel; quantity?: number }> => {
	const price = PriceCalculator.projectPrice({
		productid: productModel.id,
		offeringid: offeringModel.id,
	});

	if (!offeringModel.virtual
		&& OfferingGroups(
			offeringModel.groupid,
			['PrintTypes'],
		)
		&& !options.force
	) {
		let offeringPageCount = offeringModel.minprintpages ? offeringModel.minprintpages : 1;
		while (offeringPageCount < price.printPageCount) {
			offeringPageCount += offeringModel.pageinterval;
		}

		const backDivider = offeringModel.hasback && !OfferingGroups(
			offeringModel.groupid,
			['PhotoFrameBox'],
		)
			? 2
			: 1;
		let batchSize = price.printPageCount / backDivider;
		let packageSize = offeringPageCount / backDivider;

		if (OfferingGroups(
			productModel.group,
			['PhotoFrameBox'],
		)) {
			batchSize -= 2;
			packageSize -= 2;
		}

		if (batchSize < packageSize) {
			let dialogText = window.App.router.$t(
				'productInset',
				{
					batchSize,
					productLabel: AppDataModule.productGroupName(productModel.group),
				},
			);
			dialogText += `. ${window.App.router.$t(
				'productMoreSamePrice',
				{
					batchSize,
					packageSize,
					freeSize: packageSize - batchSize,
					productLabel: AppDataModule.productGroupName(productModel.group),
				},
			)}`;

			return new Promise((res, rej) => {
				// Show 'more photos for same price' dialog
				const closeConfirm = DialogService.openConfirmDialog({
					body: {
						content: dialogText,
					},
					footer: {
						buttons: [
							{
								id: 'cancel',
								text: window.App.router.$t('dialogButtonMorePrintsSamePriceCancel'),
								click: () => {
									if (ProductStateModule.productId) {
										rej();

										if (window.nativeToWeb) {
											window.nativeToWeb.pickerDismissed = () => {
												navigate.back();
											};
										}

										// Go to photo selection screen
										navigate.toSelect(
											ProductStateModule.productId,
											{
												removeUnselected: true,
												showSelection: false,
											},
										);
									} else {
										rej(new Error('Set incomplete'));
									}

									closeConfirm();
								},
							},
							{
								id: 'accept',
								text: window.App.router.$t('dialogButtonMorePrintsSamePriceOk'),
								click: () => {
									res({
										offeringModel,
										quantity: options.quantity,
									});
									closeConfirm();
								},
							},
						],
					},
					width: 400,
				});
			});
		}
	}

	/*
	Possible experiment: Bulk discount promotion
	if (options.quantity
		&& price.bulkDiscount
	) {
		return new Promise((resolve) => {
			const { close: closeDialog } = DialogService.openDialog({
				header: {
					hasCloseButton: false,
					// title: 'Wist je dat?',
				},
				body: {
					component: BulkDiscountSelectView,
					props: {
						bulkModel: price.bulkDiscount?.bulkModel,
						bulkQuantityModels: price.bulkDiscount?.bulkQuantityModels,
						startQuantity: options.quantity,
					},
					listeners: {
						closeDialog: (event: ServiceEvent<number>) => {
							closeDialog();
							resolve({
								offeringModel,
								quantity: event.payload,
							});
						},
					},
					// styles: {
					// 	'padding': '0',
					// },
				},
			});
		});
	} */

	return Promise.resolve({
		offeringModel,
		quantity: options.quantity,
	});
};

const addProductsToCart = (
	subItems: PricingObject[],
	options: AddProjectToCartOptions,
): Promise<DB.ShoppingCartItemModel[]> => {
	const productModel = ProductStateModule.getProduct;
	if (!productModel) {
		throw new Error('Missing required productModel');
	}

	const cartId = CartModule.id;
	if (!cartId) {
		throw new Error('Missing cart id');
	}

	const items: DB.ShoppingCartItemModel[] = [];
	let platform = 'web';

	const now = new Date();
	const defaultShippingDate = now.getTime() / 1000;

	return User.saveCart()
		.then(() => getDeviceDetails())
		.then((deviceDetails) => {
			platform = deviceDetails.platform;

			const arrExistingItems: PricingObject[] = subItems.filter(
				(subItem) => CartItemsModule.where({
					productid: options.productid,
					offeringid: subItem.offeringModel.id,
				}).length > 0,
			);
			if (!arrExistingItems.length) {
				return [];
			}

			const putData: OptionalExceptFor<DB.ShoppingCartItemModel, 'id'>[] = arrExistingItems.map(
				(subItem) => ({
					id: CartItemsModule.where({
						productid: options.productid,
						offeringid: subItem.offeringModel.id,
					})[0].id,
					quantity: options.quantity || 1,
					shippingdate: options.shippingDate || defaultShippingDate,
					pages: subItem.totalPageCount,
					printpages: subItem.printPageCount,
				}),
			);

			return CartItemsModule.putModels({
				data: putData,
			});
		})
		.then((updatedItems) => {
			items.push(
				...updatedItems,
			);

			const arrNewItems: PricingObject[] = subItems.filter(
				(subItem) => CartItemsModule.where({
					productid: options.productid,
					offeringid: subItem.offeringModel.id,
				}).length === 0,
			);
			if (!arrNewItems.length) {
				return [];
			}

			const postData: Partial<DB.ShoppingCartItemModel>[] = arrNewItems.map(
				(subItem) => ({
					cartid: cartId,
					offeringid: subItem.offeringModel.id,
					groupid: subItem.offeringModel.groupid,
					typeid: subItem.offeringModel.typeid,
					variantid: subItem.offeringModel.variantid,
					productid: options.productid,
					read_token: productModel.read_token,
					quantity: options.quantity || 1,
					shippingdate: options.shippingDate || defaultShippingDate,
					affiliateid: productModel.affiliateid,
					pages: subItem.totalPageCount,
					printpages: subItem.printPageCount,
					platform,
				}),
			);

			return CartItemsModule.createModels({
				data: postData,
			});
		})
		.then((newItems) => {
			items.push(
				...newItems,
			);
			items.forEach((cartItemModel) => {
				const subOfferingModel = AppDataModule.getOffering(cartItemModel.offeringid);
				if (!subOfferingModel) {
					throw new Error('Missing required subOfferingModel');
				}

				const subPrice = PriceCalculator.projectPrice({
					productid: options.productid,
					offeringid: subOfferingModel.id,
				});

				analytics.trackAddToCart(
					cartItemModel,
					subPrice,
				);
			});

			return items;
		});
};

const addProjectToCart = (options: AddProjectToCartOptions): Promise<DB.ShoppingCartItemModel[]> => {
	if (!AppStateModule.online) {
		// Notify user that they need to be online and save the project before it can be added to the cart
		const closeError = DialogService.openErrorDialog({
			header: {
				title: window.App.router.$t('dialogHeaderUnavailable'),
			},
			body: {
				content: window.App.router.$t('dialogTextUnavailableAddToCart'),
			},
			footer: {
				buttons: [
					{
						id: 'accept',
						text: window.App.router.$t('dialogButtonUnavailableOk'),
						click: () => {
							closeError();
						},
					},
				],
			},
		});

		return Promise.reject(
			new Error(ERRORS_OFFLINE),
		);
	}

	if (!ProductStateModule.getSaved) {
		// Wait for saving to complete
		return ProductState.finalize(
			options.showCancel,
			false,
		)
			.then((saved) => {
				if (!saved) {
					return Promise.reject();
				}

				return addProjectToCart(options);
			});
	}

	if (!ProductStateModule.getProduct) {
		return Promise.reject(
			new Error('Could not find product'),
		);
	}

	const productModel = ProductStateModule.getProduct;

	return checkProjectToCart(
		productModel,
		options,
	)
		.then(() => selectItemOffering(
			productModel,
			options,
		))
		.then((offeringModel) => pricingSuggestions(
			productModel,
			offeringModel,
			{
				force: options.force,
				quantity: options.quantity,
			},
		))
		.then(({ offeringModel, quantity }) => {
			if (quantity) {
				options.quantity = quantity;
			}

			if (!offeringModel.instock) {
				throw new Error(window.App.router.$i18next.t('offeringNotInStock'));
			}
			if (!UserModule.countryid) {
				throw new Error('Missing user country id');
			}

			const countryModel = AppDataModule.getCountry(UserModule.countryid);

			if (!countryModel) {
				throw new Error('Missing country model');
			}

			const objProjectPrice = PriceCalculator.projectPrice({
				productid: options.productid,
				offeringid: offeringModel.id,
			});

			let isAvailableInRegion = false;
			if (offeringModel.virtual) {
				const unavailableProduct = objProjectPrice.subItems?.find(
					(subItem) => AppDataModule.findRegionOfferingLinkWhere({
						regionid: countryModel.regionid,
						offeringid: subItem.offeringModel.id,
					}) == undefined,
				);
				if (!unavailableProduct) {
					isAvailableInRegion = true;
				}
			} else {
				isAvailableInRegion = !!AppDataModule.findRegionOfferingLinkWhere({
					regionid: countryModel.regionid,
					offeringid: offeringModel.id,
				});
			}

			if (!isAvailableInRegion) {
				const closeError = DialogService.openErrorDialog({
					body: {
						content: window.App.router.$t('productNotAvailableInRegion'),
					},
					footer: {
						buttons: [
							{
								id: 'cancel',
								text: window.App.router.$t('dialogButtonCancel'),
								click: () => {
									closeError();
								},
							},
							{
								id: 'accept',
								text: window.App.router.$t('buttonSwitchRegion'),
								click: () => {
									showSettings();
									closeError();
								},
							},
						],
					},
				});

				return Promise.reject();
			}

			const closeLoader = DialogService.openLoaderDialog();

			if (objProjectPrice.subItems && objProjectPrice.subItems.length) {
				return addProductsToCart(
					objProjectPrice.subItems,
					options,
				).then((cartItemModels) => {
					closeLoader();
					return cartItemModels;
				});
			}

			return addProductsToCart(
				[objProjectPrice],
				options,
			).then(([cartItemModel]) => {
				// Get all possible upsell items for this offering
				const upsellItems = AppDataModule.whereUpsell({
					offeringid: cartItemModel.offeringid,
					autoinclude: 1,
				});

				const arrUpsellPromises: Promise<DB.ShoppingCartItemModel[]>[] = [];

				upsellItems.forEach((upsellItem) => {
					const itemQuantity = upsellItem.peritem
						? cartItemModel.quantity
						: 1;

					const cartItemUpsellModel = CartItemsModule.findWhere({
						shoppingcartitemid: cartItemModel.id,
						offeringid: upsellItem.upsellid,
					});

					// Add auto included upsell item to cart
					const data = JSON.parse(JSON.stringify(cartItemModel));
					delete data.id;
					data.synced = false;
					data.shoppingcartitemid = cartItemModel.id;
					data.offeringid = upsellItem.upsellid;
					data.quantity = itemQuantity;

					const strOfferingId = `${upsellItem.upsellid}`;
					data.groupid = parseInt(
						strOfferingId.substring(
							0,
							3,
						),
						10,
					);
					data.typeid = parseInt(
						strOfferingId.substring(
							3,
							7,
						),
						10,
					);
					data.variantid = parseInt(
						strOfferingId.substring(7),
						10,
					);

					if (cartItemUpsellModel) {
						const doublePromise = CartItemsModule
							.destroyModel({
								id: cartItemUpsellModel.id,
							})
							.then(() => (
								CartItemsModule.createModels({
									data: [data],
								})
							));
						arrUpsellPromises.push(doublePromise);
					} else {
						const singlePromise = CartItemsModule.createModels({
							data: [data],
						});
						arrUpsellPromises.push(singlePromise);
					}
				});

				return Promise
					.all(arrUpsellPromises)
					.then(() => {
						closeLoader();

						return selectUpsellItems(cartItemModel);
					})
					.then(() => [cartItemModel])
					.catch(() => [cartItemModel]);
			});
		})
		.then((cartItemModels) => {
			const offeringModel = ProductStateModule.getOffering;

			if (offeringModel?.virtual) {
				// Check if this "virtual" project has lost any children that are related to existing shopping cart items
				const modelIds = _.pluck(
					cartItemModels,
					'id',
				);
				const itemsToDelete = CartItemsModule.collection.filter((cartItemModel) => (
					cartItemModel.productid == productModel.id
					&& modelIds.indexOf(cartItemModel.id) === -1
				));

				while (itemsToDelete.length) {
					const cartItemModel = itemsToDelete.shift();

					if (cartItemModel) {
						CartItemsModule.destroyModel({
							id: cartItemModel.id,
						});
					}
				}
			}

			return cartItemModels;
		})
		.finally(DialogService.closeLoaderDialog);
};

export default addProjectToCart;
