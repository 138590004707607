import './defines';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'SavingProjectView',
	emits: ['cancel'],
	mixins: [Template],
})
class SavingProjectView extends Vue {
	@Prop({ type: Boolean, default: false })
	public hasCancel!: boolean;

	onCancel() {
		this.$emit('cancel');
	}
}

export default toNative(SavingProjectView);
