import Dialog from 'classes/dialog';
import { DialogModel } from 'interfaces/app';
import { DialogModule } from 'store/index';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import AuthDialogView from 'views/dialog-auth.vue';
import DefaultDialogView from 'views/dialog-default.vue';
import LoaderDialogView from 'views/dialog-loader.vue';
import { Vue } from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		AuthDialogView,
		DefaultDialogView,
		LoaderDialogView,
	},
	mixins: [Template],
})
class DialogsView extends Vue {
	get auth() {
		return DialogModule.auth;
	}

	get error() {
		return DialogModule.error;
	}

	get loader() {
		return DialogModule.loader;
	}

	get main() {
		return DialogModule.main;
	}

	closeDialog(dialogModel: DialogModel) {
		Dialog.closeDialog(dialogModel);
	}
}

export default toNative(DialogsView);
