import './defines';
import ProgressBar from 'components/progress-bar';
import SpinnerComponent from 'components/spinner';
import { PhotoModel } from 'interfaces/project';
import { PhotosModule } from 'store';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		SpinnerComponent,
		ProgressBar,
	},
	emits: ['closeDialog'],
	mixins: [Template],
})
class VectorizationView extends Vue {
	@Prop({
		required: true,
		type: Array,
	})
	public readonly models!: PhotoModel[];

	private interval = 0;

	protected progressPercentage = 0;

	protected mounted(): void {
		this.progressPercentage = 1;
		this.interval = window.setInterval(
			this.checkReady,
			100,
		);
	}

	protected beforeUnmount(): void {
		clearInterval(this.interval);
	}

	@Watch('progressPercentage')
	protected onProgressUpdate(): void {
		if (this.progressPercentage < 99) {
			// Since we have no idea how long the process will take, we are
			// simulating a progress bar so that the user has the feeling of progress
			setTimeout(
				() => {
					this.progressPercentage += 1;
				},
				this.progressPercentage ** 1.4,
			);
		} else if (this.progressPercentage === 100) {
			this.$emit('closeDialog');
		}
	}

	private checkReady(): void {
		const unsavedModel = this.models.find((modelToConvert) => {
			const photoModel = PhotosModule.findWhere({
				_localRef: modelToConvert._localRef,
			});
			if (photoModel) {
				return typeof photoModel.id === 'string'
					|| !photoModel.url;
			}

			return false;
		});

		if (!unsavedModel) {
			this.progressPercentage = 100;
		}
	}
}

export default toNative(VectorizationView);
