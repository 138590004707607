import './defines';
import GoogleIcon from '@root/svg/google-icon.svg';
import connector from 'controllers/connector';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import { Vue } from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		GoogleIcon,
	},
	emits: ['buttonClicked'],
	mixins: [Template],
})
class GoogleButtonView extends Vue {
	private disabled = true;

	protected mounted() {
		connector
			.setup('gplus')
			.then(() => connector.init('gplus'))
			.then(() => {
				this.disabled = false;
			})
			.catch(() => {
				// Swallow error: no action required
			});
	}

	protected clickButton() {
		if (!this.disabled) {
			this.$emit('buttonClicked');
		}
	}
}

export default toNative(GoogleButtonView);
