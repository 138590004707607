import * as CSS from 'csstype';
import { DialogModel } from 'interfaces/app';
import { DialogModule, ProgressModule } from 'store';
import _ from 'underscore';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Pikaday from './pikaday';

@Component({
	components: {
		Pikaday,
	},
	emits: ['closeDialog'],
})
class DefaultDialogView extends Vue {
	@Prop({ required: true, type: Object })
	readonly dialogModel!: DialogModel;

	private isMobile = true;

	private maxWidth = window.innerWidth;

	private maxBodyHeight = 0;

	private mediaQueryList = window.matchMedia('(max-width: 767px)');

	private posY = 0;

	private showButtons = true;

	get maxHeight() {
		return window.innerHeight - this.spacing;
	}

	get bodyStyle() {
		return this.dialogModel.bodyStyle || {};
	}

	get bodyText() {
		return this.dialogModel.text;
	}

	get buttonHolderClass() {
		let buttonHolderClass;
		if (this.dialogModel.multipleButtons) {
			buttonHolderClass = 'multipleButtons';
		} else if (this.buttons.length === 2) {
			buttonHolderClass = 'doubleButtons';
		} else if (this.buttons.length === 1) {
			buttonHolderClass = 'singleButton';
		} else {
			buttonHolderClass = 'multipleButtons';
		}

		if (this.dialogModel.wrapButtons) {
			buttonHolderClass += ' wrap';
		}
		return buttonHolderClass;
	}

	get buttons() {
		return this.dialogModel.buttons;
	}

	get childView() {
		return this.dialogModel.vueView;
	}

	get componentProps() {
		if (this.dialogModel.vueViewProps) {
			return this.dialogModel.vueViewProps();
		}

		if (this.dialogModel.vueProps) {
			return this.dialogModel.vueProps;
		}

		return undefined;
	}

	get componentListeners() {
		return this.dialogModel.vueListeners || {};
	}

	get modalClass() {
		if (!this.dialogModel.modal) {
			return null;
		}
		return this.dialogModel.lightModal ? 'lightModal' : 'modal';
	}

	get posX() {
		return -Math.min(
			this.dialogModel.width,
			this.maxWidth - this.spacing,
		) / 2;
	}

	get progressValue() {
		return ProgressModule.getPercentage;
	}

	get progressLabel() {
		return ProgressModule.getLabel;
	}

	get showDialogChrome() {
		return this.dialogModel.chrome && !this.dialogModel.focusMode;
	}

	get spacing() {
		return this.dialogModel.focusMode
			? 0
			: 20;
	}

	get styleObject() {
		const styleObject: CSS.Properties = {
			width: `${this.width}px`,
			maxHeight: `${this.maxHeight}px`,
			marginTop: `${this.posY}px`,
			marginLeft: `${this.posX}px`,
		};
		if (this.dialogModel.focusMode && this.isMobile) {
			styleObject.height = `${this.maxHeight}px`;
		}

		return styleObject;
	}

	get width() {
		return Math.min(
			this.dialogModel.width,
			this.maxWidth - this.spacing,
		);
	}

	@Watch('childView')
	onDefaultDialogChildViewChange() {
		if (this.dialogModel.show && this.dialogModel.vueView) {
			this.$nextTick(this.centerScreen);
		}
	}

	created() {
		this.isMobile = this.mediaQueryList.matches;
		this.posY = this.dialogModel.posy;
	}

	mounted() {
		this.centerScreen();

		if (this.dialogModel.type == 'prompt') {
			this.autoFocus();
		}

		if (this.dialogModel.show && this.dialogModel.vueView) {
			this.$nextTick(this.centerScreen);
		}

		window.addEventListener(
			'resize',
			this.resize,
		);

		if (this.mediaQueryList.addEventListener) {
			this.mediaQueryList.addEventListener(
				'change',
				this.checkMobile,
			);
		} else {
			// Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
			this.mediaQueryList.addListener(this.checkMobile);
		}
	}

	updated() {
		this.centerScreen();

		if (this.dialogModel.type == 'progress' && this.progressValue >= 100) {
			// Automatically close progress dialog when the process completes
			this.closeDialog();
		}
	}

	beforeUnmount() {
		window.removeEventListener(
			'resize',
			this.resize,
		);

		if (this.mediaQueryList.removeEventListener) {
			this.mediaQueryList.removeEventListener(
				'change',
				this.checkMobile,
			);
		} else {
			// Deprecated 'MediaQueryList' API, <Safari 14, IE, <Edge 16
			this.mediaQueryList.removeListener(this.checkMobile);
		}
	}

	acceptConfirm() {
		// If dialog has a callback function set: run it
		if (_.findWhere(
			this.buttons,
			{ id: 'accept' },
		)) {
			this.selectButton('accept');
		} else {
			this.closeDialog();
		}
	}

	autoFocus() {
		if (this.dialogModel.data) {
			const firstInputField = this.dialogModel.data[0];
			const [el] = this.$refs[firstInputField.id] as HTMLInputElement[];
			el.focus();
		}
	}

	clickModal() {
		if (this.dialogModel.modal) {
			this.checkClose();
		}
	}

	changeDate(data: Date) {
		if (this.dialogModel.onDateChange) {
			this.dialogModel.onDateChange(data);
		}
	}

	checkClose() {
		if (this.dialogModel.hasclose) {
			if (this.dialogModel.type == 'alert') {
				if (this.buttons && _.findWhere(
					this.buttons,
					{ id: 'cancel' },
				)) {
					this.selectButton('cancel');
				} else {
					this.acceptConfirm();
				}
			} else {
				this.closeDialog();
			}
		}
	}

	checkMobile(event: MediaQueryListEvent) {
		if (event.matches) {
			this.isMobile = true;
		} else {
			this.isMobile = false;
		}
	}

	closeDialog(payload?: any) {
		// Execute any custom handlers
		if (this.dialogModel.beforeClose && _.isFunction(this.dialogModel.beforeClose)) {
			this.dialogModel.beforeClose(payload);
			DialogModule.removeHooks(this.dialogModel);
		}

		if (this.dialogModel.checkBoxes) {
			this.dialogModel.checkBoxes.forEach((checkBox) => {
				if (checkBox.target) {
					const [el] = this.$refs[`checkbox-${checkBox.id}`] as HTMLInputElement[];
					checkBox.target(el.checked);
				}
			});
		}

		// Emit close event to parent view
		this.$emit(
			'closeDialog',
			this.dialogModel,
		);
	}

	centerScreen() {
		// Calculate (maximum) height of the dialog (body)
		let maxBodyHeight = this.maxHeight;
		let dialogHeight = 0;

		if (this.$refs.dialog && this.$refs.dialog instanceof Element) {
			const style = window.getComputedStyle(this.$refs.dialog);
			if (style) {
				dialogHeight += parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
				maxBodyHeight -= (parseFloat(style.paddingTop) + parseFloat(style.paddingBottom));
			}
		}

		if (this.$refs.dialogHeader && this.$refs.dialogHeader instanceof Element) {
			let headerHeight = this.$refs.dialogHeader.clientHeight;

			const style = window.getComputedStyle(this.$refs.dialogHeader);
			if (style) {
				// clientHeight includes padding, but not margins, so add this manually
				headerHeight += parseFloat(style.marginTop);
				headerHeight += parseFloat(style.marginBottom);
			}

			maxBodyHeight -= headerHeight;
			dialogHeight += headerHeight;
		}

		if (this.$refs.dialogBody && this.$refs.dialogBody instanceof Element) {
			dialogHeight += this.$refs.dialogBody.clientHeight;

			const style = window.getComputedStyle(this.$refs.dialogBody);
			if (style) {
				maxBodyHeight -= parseFloat(style.marginTop);
				maxBodyHeight -= parseFloat(style.marginBottom);
			}
		}

		// The button container is optional
		if (this.$refs.dialogButtons && this.$refs.dialogButtons instanceof Element) {
			let buttonsHeight = this.$refs.dialogButtons.clientHeight;

			const style = window.getComputedStyle(this.$refs.dialogButtons);
			if (style) {
				// clientHeight includes padding, but not margins, so add this manually
				buttonsHeight += parseFloat(style.marginTop);
				buttonsHeight += parseFloat(style.marginBottom);
			}

			maxBodyHeight -= buttonsHeight;
			dialogHeight += buttonsHeight;
		}

		// Dialog may not be bigger than viewport size
		dialogHeight = this.dialogModel.focusMode && this.isMobile
			? this.maxHeight
			: Math.min(
				this.maxHeight,
				dialogHeight,
			);

		this.maxBodyHeight = maxBodyHeight;
		this.posY = -dialogHeight / 2;
	}

	resize() {
		this.maxWidth = window.innerWidth;
	}

	selectButton(id: string) {
		// Hide buttons
		this.showButtons = false;

		let val: DialogModel['data'];
		if (this.dialogModel.type == 'prompt' && this.dialogModel.data) {
			val = JSON.parse(JSON.stringify(this.dialogModel.data)) as {
				[key: string]: any;
			}[];
			val.forEach((data) => {
				const [el] = this.$refs[data.id] as HTMLInputElement[];
				data.value = el.value;
			});
		}

		const buttonModel = _.findWhere(
			this.buttons,
			{ id },
		);

		if (
			buttonModel
			&& buttonModel.target
		) {
			this.showButtons = true;
			const event = {
				prevented: false,
			};

			buttonModel.target.call(
				this,
				val,
				{
					preventDefault() {
						event.prevented = true;
					},
				},
			);

			if (!event.prevented) {
				this.closeDialog();
			}
		} else {
			this.closeDialog();
		}
	}
}

export default toNative(DefaultDialogView);
