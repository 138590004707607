import './defines';
import type { UserSettingsCountriesDataModel } from 'interfaces/app';
import type {
	CurrencyModel,
	LanguageModel,
} from 'interfaces/database';
import { AppDataModule } from 'store';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'UserSettingsView',
	emits: [
		'update:countryid',
		'update:currency',
		'update:language',
	],
	mixins: [Template],
})
class UserSettingsView extends Vue {
	@Prop({
		default: null,
		event: 'update:countryid',
		type: Number,
	})
	public readonly countryid!: number | null;

	@Prop({
		default: null,
		event: 'update:currency',
		type: String,
	})
	public readonly currency!: string | null;

	@Prop({
		default: window.locale,
		event: 'update:language',
		type: String,
	})
	public readonly language!: string;

	protected get countries(): UserSettingsCountriesDataModel {
		return AppDataModule
			.countries
			.map((countryModel) => ({
				id: countryModel.id,
				name: AppDataModule.getCountryName(countryModel.id),
			}))
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	protected get currencies(): CurrencyModel[] {
		return this.countryid
			? AppDataModule.getCurrencyByCountryId(this.countryid)
			: AppDataModule.currencies;
	}

	protected get languages(): LanguageModel[] {
		return AppDataModule.languages;
	}

	protected onCountryIdChange(event: InputEvent) {
		const countryid = (event.target as HTMLSelectElement).value as number | string;
		this.$emit(
			'update:countryid',
			parseInt(
				String(countryid),
				10,
			),
		);
	}

	protected onCurrencyChange(event: InputEvent) {
		const currency = (event.target as HTMLSelectElement).value as string;
		this.$emit(
			'update:currency',
			currency,
		);
	}

	protected onLanguageChange(event: InputEvent) {
		const language = (event.target as HTMLSelectElement).value as string;
		this.$emit(
			'update:language',
			language,
		);
	}
}

export default toNative(UserSettingsView);
