import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'UploadQrView',
	emits: ['close'],
	mixins: [Template],
})
class UploadQrView extends Vue {
	@Prop({
		description: 'The QR code image',
		required: true,
		type: String,
	})
	public qrImage!: string;

	@Prop({
		description: 'The QR code url',
		required: true,
		type: String,
	})
	public qrLink!: string;

	protected onCopyLink(): void {
		window.navigator.clipboard.writeText(
			this.qrLink,
		).then(() => {
			const closeDialog = this.$openAlertDialog({
				header: {
					title: this.$t('dialogs.linkCopiedToClipboard.title'),
				},
				body: {
					content: this.$t('dialogs.linkCopiedToClipboard.message'),
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: this.$t('dialogs.linkCopiedToClipboard.buttons.accept'),
							click: () => {
								closeDialog();
							},
						},
					],
				},
			});
		});
	}

	protected onCloseClick(): void {
		this.$emit('close');
	}
}

export default toNative(UploadQrView);
