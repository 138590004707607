import openWindow from 'ops/open-window';
import * as DialogService from 'services/dialog';
import getDelegateUrl from 'services/get-delegate-url';

const resolveDelegation = (
	url: string,
	delegate: boolean,
): Promise<string> => {
	if (delegate) {
		const closeLoader = DialogService.openLoaderDialog();

		return getDelegateUrl(url)
			.finally(closeLoader);
	}

	return Promise.resolve(url);
};

const openUrl = (
	url: string,
	delegate: boolean,
) => {
	resolveDelegation(
		url,
		delegate,
	).then((resolvedUrl) => {
		if (window.glPlatform === 'native') {
			openWindow(
				resolvedUrl,
				{
					width: undefined,
					height: undefined,
					inApp: true,
				},
			);
		} else {
			window.location.href = resolvedUrl;
		}
	});
};

export default openUrl;
