import User from 'classes/user';
import analytics from 'controllers/analytics';
import storage from 'controllers/storage';
import * as DialogService from 'services/dialog';
import { UserModule } from 'store';
import setNativeLanguage from 'tools/set-native-language';
import UserSettingsView from 'views/user-settings';

export default () => {
	let {
		countryid,
		currency,
	} = UserModule;
	let language = (
		UserModule.language
		|| window.locale
	);

	// Show user settings dialog
	const {
		api: apiDialog,
		close: closeDialog,
	} = DialogService.openDialog({
		header: {
			hasCloseButton: false,
			title: window.App.router.$t('dialogHeaderUserSettings'),
		},
		body: {
			component: UserSettingsView,
			props: {
				countryid,
				currency,
				language,
			},
			listeners: {
				'update:countryid': (value: number) => {
					countryid = value;
					const bodyComponent = apiDialog.bodyComponent();

					if (bodyComponent) {
						bodyComponent.countryid = countryid;
					}
				},
				'update:currency': (value: string) => {
					currency = value;
					const bodyComponent = apiDialog.bodyComponent();

					if (bodyComponent) {
						bodyComponent.currency = currency;
					}
				},
				'update:language': (value: string) => {
					language = value;
					const bodyComponent = apiDialog.bodyComponent();

					if (bodyComponent) {
						bodyComponent.language = language;
					}
				},
			},
		},
		footer: {
			buttons: [
				{
					id: 'accept',
					text: window.App.router.$t('dialogButtonUserSettingsOk'),
					click: () => {
						if (countryid !== UserModule.countryid) {
							UserModule.set({
								countryid,
							});
						}
						if (language !== UserModule.language) {
							UserModule.set({
								language,
							});
						}
						if (currency !== UserModule.currency) {
							UserModule.set({
								currency,
							});
						}

						const closeLoader = DialogService.openLoaderDialog();
						let reloadRouter = false;
						User
							.validate()
							.then(() => (
								UserModule
									.put()
									.finally(() => {
										if (
											language
											&& language != window.locale
										) {
											storage.set(
												'locale',
												language,
											);
											window.locale = language;

											if (window.glPlatform === 'native') {
												// Add translation strings for native interface
												setNativeLanguage(language);
											}
										}

										if (!UserModule.id) {
											storage.set(
												'countryid',
												UserModule.countryid,
											);
										}

										return window.App.router.$i18next.changeLanguage(language);
									})
									.then(() => {
										// Send updated properties to analytics providers
										analytics.setUserProperties();
										reloadRouter = true;
									})
									.catch(() => {
										// Swallow error: no action required
									})
							))
							.finally(() => {
								closeLoader();
								closeDialog();

								if (reloadRouter) {
									window.App.router.reload();
								}
							});
					},
				},
			],
		},
		width: 325,
	});
};
