import {
	OfferingFrameModel,
	PageObjectVars,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import {
	AppDataModule,
	FontModule,
	PhotosModule,
	ProductStateModule,
	ThemeDataModule,
} from 'store';
import breakText from 'tools/break-text';
import formatAddress from 'tools/format-address';
import maxObjectSize from 'tools/max-object-size';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Inject,
	Prop,
	Vue,
} from 'vue-facing-decorator';

@Component({})
class DrawView extends Vue {
	@Inject
	readonly canvas!: {
		context: CanvasRenderingContext2D;
	};

	@Prop({ default: null, type: Object })
	readonly addressModel!: DB.AddressModel;

	@Prop({ default: 0, type: Number })
	readonly bleedMargin!: number;

	@Prop({ default: '#000', type: String })
	readonly color!: string;

	@Prop({ default: false, type: Boolean })
	readonly drawMask!: boolean;

	@Prop({ default: () => [], type: Array })
	readonly drawObjects!: PI.PageObjectModel[];

	@Prop({ default: false, type: Boolean })
	readonly drawOverlay!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly drawPage!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly mirrorOverlay!: boolean;

	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	@Prop({ required: true, type: Number })
	readonly scaling!: number;

	@Prop({ default: true, type: Boolean })
	readonly showLoaders!: boolean;

	get offeringFrameImage() {
		if (!this.drawOverlay
			|| !this.offeringFrameModel
		) {
			return undefined;
		}

		return AppDataModule.findOfferingFrameImage(
			this.offeringFrameModel.imageModel,
		);
	}

	get offeringFrameModel(): OfferingFrameModel | undefined {
		if (!this.drawOverlay) {
			return undefined;
		}

		if (!this.offeringModel) {
			return undefined;
		}

		return AppDataModule.getOfferingFrame(
			this.offeringModel.id,
			this.pageIndex,
		);
	}

	get offeringMaskImage() {
		return ProductStateModule.getMaskImage;
	}

	get offeringModel() {
		return this.pageModel.offeringId
			? AppDataModule.getOffering(this.pageModel.offeringId)
			: ProductStateModule.getOffering;
	}

	get pageIndex() {
		return ProductStateModule.getPageIndex(this.pageModel);
	}

	created() {
		if (this.drawMask && !this.offeringMaskImage) {
			ProductStateModule.loadMask();
		}
	}

	drawObject(objectModel: PI.PageObjectModel) {
		const { context } = this.canvas;
		const vars = this.calculatePosition(
			objectModel,
			this.scaling,
		);

		if (vars.flop) {
			// horizontal
			context.scale(
				-1,
				1,
			);
		}
		if (vars.flip) {
			// vertical
			context.scale(
				1,
				-1,
			);
		}

		context.translate(
			vars.draw.x,
			vars.draw.y,
		);
		context.rotate(vars.rotate);

		if (objectModel.type == 'photo') {
			if (
				objectModel._image
				&& (!objectModel.borderimage || objectModel._borderimage)
				&& (!objectModel.mask || objectModel._mask || objectModel._canvas)
			) {
				this.drawPhotoObject(
					objectModel,
					vars,
				);
			} else if (this.showLoaders) {
				this.drawLoader(
					vars.width,
					vars.height,
					0xf03e,
				);
			}
		} else if ((objectModel.type == 'text' || objectModel.type == 'message') && objectModel.fontface) {
			const fontModel = FontModule.getById(objectModel.fontface);
			if (fontModel && fontModel._loaded) {
				this.drawTextObject(
					objectModel,
					vars,
				);
			} else if (this.showLoaders) {
				this.drawLoader(
					vars.width,
					vars.height,
					0xf031,
				);
			}
		}

		if (this.drawMask && ProductStateModule.getMaskImage && !this.drawPage) {
			const offset = this.bleedMargin;
			const { width, height } = this.pageModel;

			context.save();
			context.globalCompositeOperation = 'destination-in';

			context.drawImage(
				ProductStateModule.getMaskImage,
				offset - vars.x_axis,
				offset - vars.y_axis,
				width,
				height,
			);

			context.restore();
		}

		context.rotate(-vars.rotate);
		context.translate(
			-vars.draw.x,
			-vars.draw.y,
		);
		if (vars.flip) {
			context.scale(
				1,
				-1,
			);
		}
		if (vars.flop) {
			context.scale(
				-1,
				1,
			);
		}
	}

	drawLoader(
		width: number,
		height: number,
		charCode = 0xf252,
	) {
		const { context } = this.canvas;
		context.globalAlpha = 0.5;
		context.strokeStyle = this.color;
		context.fillStyle = this.color;
		context.lineWidth = Math.min(
			width,
			height,
		) / 10;

		context.strokeRect(
			0,
			0,
			width,
			height,
		);

		const fontsize = Math.min(
			width / 2,
			height / 2,
		);
		context.font = `${fontsize}px "Font Awesome 5 Pro"`;
		context.textAlign = 'center';
		context.textBaseline = 'middle';

		context.fillText(
			String.fromCharCode(charCode),
			Math.round(width / 2),
			Math.round(height / 2),
		);
		context.globalAlpha = 1;
	}

	drawPhotoObject(
		objectModel: PI.PageObjectModel,
		vars: PageObjectVars,
	) {
		const { context } = this.canvas;
		if (vars.borderwidth > 0 && !objectModel.borderimage) {
			context.fillStyle = vars.bordercolor && vars.bordercolor.length > 0 ? vars.bordercolor : '#000000';
			context.fillRect(
				-vars.borderwidth,
				-vars.borderwidth,
				(vars.width + 2 * vars.borderwidth),
				(vars.height + 2 * vars.borderwidth),
			);
		}

		if (vars.cropwidth > 0) {
			if (objectModel._canvas && this.drawPage) {
				// Note: If we use clipping here we sometimes loose objects on the canvas (weird!)
				context.drawImage(
					objectModel._canvas,
					vars.max.x_axis - vars.x_axis,
					vars.max.y_axis - vars.y_axis,
					vars.max.width,
					vars.max.height,
				);
			} else if (objectModel._image) {
				// Note: iOS has serious issues with clipping within the drawImage method (results in squashed image) but works with this alternative method
				context.save();
				context.beginPath();
				context.rect(
					0,
					0,
					Math.round(vars.width),
					Math.round(vars.height),
				);
				context.clip();
				context.drawImage(
					objectModel._image,
					vars.max.x_axis - vars.x_axis,
					vars.max.y_axis - vars.y_axis,
					vars.max.width,
					vars.max.height,
				);
				context.restore();
			}
		} else if (objectModel._image) {
			context.drawImage(
				objectModel._image,
				0,
				0,
				Math.round(vars.width),
				Math.round(vars.height),
			);
		}

		if (objectModel.mask && objectModel._mask && !this.drawPage) {
			context.save();
			context.globalCompositeOperation = 'destination-in';
			context.drawImage(
				objectModel._mask,
				0,
				0,
				vars.width,
				vars.height,
			);

			if (!objectModel._canvas && vars.borderwidth > 0) {
				context.globalCompositeOperation = 'destination-over';
				context.fillStyle = vars.bordercolor && vars.bordercolor.length > 0 ? vars.bordercolor : '#000000';
				context.fillRect(
					-vars.borderwidth,
					-vars.borderwidth,
					(vars.width + 2 * vars.borderwidth),
					(vars.height + 2 * vars.borderwidth),
				);
			}

			context.restore();
		}

		if (objectModel.borderimage && objectModel._borderimage) {
			const borderImageModel = ThemeDataModule.getBorderImage(objectModel.borderimage);
			if (borderImageModel) {
				const borderImageInnerWidth = borderImageModel.width - borderImageModel.borderwidth_left - borderImageModel.borderwidth_right;
				const borderscale = vars.width / borderImageInnerWidth;
				const borderWidthTop = Math.round(borderImageModel.borderwidth_top * borderscale);
				const borderWidthRight = Math.round(borderImageModel.borderwidth_right * borderscale);
				const borderWidthBottom = Math.round(borderImageModel.borderwidth_bottom * borderscale);
				const borderWidthLeft = Math.round(borderImageModel.borderwidth_left * borderscale);

				context.drawImage(
					objectModel._borderimage,
					-borderWidthLeft,
					-borderWidthTop,
					Math.round(vars.width) + borderWidthLeft + borderWidthRight,
					Math.round(vars.height) + borderWidthTop + borderWidthBottom,
				);
			}
		}
	}

	drawTextObject(
		objectModel: PI.PageObjectModel,
		vars: PageObjectVars,
	) {
		const { context } = this.canvas;
		if (objectModel.fontface && objectModel.pointsize && objectModel.text) {
			const fontModel = FontModule.getById(objectModel.fontface);
			if (fontModel) {
				if (objectModel.bgcolor && objectModel.bgcolor.length > 0) {
					context.fillStyle = objectModel.bgcolor;
				} else context.fillStyle = 'rgba(0,0,0,0)';
				const indent = 0; // indent to position text in relation to x_axis object
				let indentAlign = 0; // extra indent required to compensate for canvas alignment method
				const border = 0;

				context.strokeStyle = '#a9a9a9';
				context.fillRect(
					indent,
					0,
					vars.width - indent,
					vars.height,
				);
				const font = objectModel.fontface;
				const pxsize = objectModel.pointsize * 31496 / 15120;
				const lineheight = pxsize * 1.25;
				const { cropx, cropy } = objectModel;

				if (fontModel.url) {
					if (objectModel.fontitalic && objectModel.fontbold && fontModel.bolditalic) {
						context.font = `${pxsize}px ${font} BoldItalic`;
					} else if (objectModel.fontitalic && fontModel.italic) {
						context.font = `${pxsize}px ${font} Italic`;
					} else if (objectModel.fontbold && fontModel.bold) {
						context.font = `${pxsize}px ${font} Bold`;
					} else {
						context.font = `${pxsize}px ${font}`;
					}
				} else if (objectModel.fontitalic && objectModel.fontbold && fontModel.bolditalic) {
					context.font = `italic bold ${pxsize}px ${font}`;
				} else if (objectModel.fontitalic && fontModel.italic) {
					context.font = `italic ${pxsize}px ${font}`;
				} else if (objectModel.fontbold && fontModel.bold) {
					context.font = `bold ${pxsize}px ${font}`;
				} else {
					context.font = `${pxsize}px ${font}`;
				}

				context.fillStyle = objectModel.fontcolor && objectModel.fontcolor.length > 0 ? objectModel.fontcolor : '#000000';
				context.textBaseline = 'middle';
				switch (objectModel.align) {
					case 'Right':
						context.textAlign = 'right';
						indentAlign = vars.width - indent - border;
						break;
					case 'Center':
						context.textAlign = 'center';
						indentAlign = (vars.width - indent - border) / 2;
						break;
					case 'Left':
					default:
						context.textAlign = 'left';
						break;
				}

				if (
					objectModel.text_formatted
					&& objectModel.text_formatted.length > 0
				) {
					if (objectModel.text_formatted_for_canvas) {
						objectModel.text_formatted_for_canvas
							.split('\n')
							.forEach((text, linenr) => {
								context.fillText(
									text,
									indent + indentAlign + border + cropx,
									border + (0.5 + linenr) * lineheight + cropy,
								);
							});
					} else {
						objectModel.text_formatted
							.split('\n')
							.forEach((text, linenr) => {
								context.fillText(
									text,
									indent + indentAlign + border + cropx,
									border + (0.5 + linenr) * lineheight + cropy,
								);
							});
					}
				} else {
					const patt = new RegExp('<% .+? %>');
					if (objectModel.text.length > 0 && !patt.test(objectModel.text)) {
						context.fillText(
							objectModel.text,
							indent + indentAlign + border + cropx,
							border + 0.5 * lineheight + cropy,
						);
					}
				}

				if (vars.borderwidth > 0) {
					const rectangle = {
						x: -vars.borderwidth,
						y: -vars.borderwidth,
						width: (vars.width + 2 * vars.borderwidth),
						height: (vars.height + 2 * vars.borderwidth),
					};

					context.fillRect(
						rectangle.x,
						rectangle.y,
						rectangle.width,
						rectangle.height,
					);
				}
			} else {
				throw new Error('Could not find required font model');
			}
		}
	}

	drawAddress(addressModel: DB.AddressModel) {
		const fontface = 'Reenie Beanie';
		const fontModel = FontModule.getById(fontface);

		if (fontModel) {
			const { context } = this.canvas;
			const textWidth = this.pageModel.width / 2 - 80;
			const defaultPxSize = 18 * 31496 / 15120;
			const lineHeight = defaultPxSize * 1.25;

			context.save();
			context.translate(
				this.pageModel.width / 2 + 40,
				this.pageModel.height / 3,
			);

			if (fontModel._loaded) {
				context.strokeStyle = '#000000';
				context.fillStyle = '#003399';
				context.textBaseline = 'alphabetic';
				context.lineWidth = 1;
				context.textAlign = 'left';

				const arrLines = formatAddress(addressModel);
				arrLines.forEach((lineText, i) => {
					const response = breakText({
						phrase: lineText,
						maxPxLength: textWidth,
						maxPxHeight: lineHeight,
						fontface,
						bold: false,
						italic: false,
						pointsize: 18,
						resize: {
							up: 18,
							down: 12,
						},
						subset: ['latin'],
					});

					let pxsize = defaultPxSize;
					if (response && response.pointsize) {
						pxsize = response.pointsize * 31496 / 15120;
					}
					const y = lineHeight + i * 60;

					context.font = `${pxsize}px ${fontface}`;
					context.fillText(
						lineText,
						0,
						y,
					);
					context.beginPath();
					context.moveTo(
						0,
						y + 2.5,
					);
					context.lineTo(
						textWidth,
						y + 2.5,
					);
					context.closePath();
					context.stroke();
				});
			} else {
				this.drawLoader(
					textWidth,
					lineHeight * 5,
					0xf252,
				);
			}

			context.translate(
				-this.pageModel.width / 2 + 40,
				-this.pageModel.height / 3,
			);
			context.restore();
		} else {
			throw new Error('Could not find required font model');
		}
	}

	calculatePosition(
		objectModel: PI.PageObjectModel,
		scaling: number,
	) {
		const photoModel = objectModel.photoid
			? PhotosModule.getById(objectModel.photoid)
			: undefined;
		const photoData = photoModel
			? {
				width: photoModel.full_width,
				height: photoModel.full_height,
			}
			: undefined;
		const maxsize = maxObjectSize(
			{
				maxwidth: objectModel.maxwidth,
				maxheight: objectModel.maxheight,
				cropwidth: objectModel.cropwidth,
				cropheight: objectModel.cropheight,
				type: objectModel.type,
			},
			photoData,
			this.offeringModel,
		);
		const dpiScale = objectModel.maxwidth / maxsize.width;

		const vars: PageObjectVars = {
			x_axis: objectModel.x_axis + this.bleedMargin,
			y_axis: objectModel.y_axis + this.bleedMargin,
			width: objectModel.width,
			height: objectModel.height,
			maxwidth: maxsize.width,
			maxheight: maxsize.height,
			cropx: objectModel.cropx,
			cropy: objectModel.cropy,
			cropwidth: objectModel.cropwidth,
			cropheight: objectModel.cropheight,
			borderwidth: objectModel.borderwidth,
			bordercolor: objectModel.bordercolor,
			flop: objectModel.flop,
			flip: objectModel.flip,
			angle: objectModel.rotate,
			topleft: {
				x: 0,
				y: 0,
			},
			topright: {
				x: 0,
				y: 0,
			},
			bottomright: {
				x: 0,
				y: 0,
			},
			bottomleft: {
				x: 0,
				y: 0,
			},
			imagemap: {
				topleft: {
					x: 0,
					y: 0,
				},
				topright: {
					x: 0,
					y: 0,
				},
				bottomright: {
					x: 0,
					y: 0,
				},
				bottomleft: {
					x: 0,
					y: 0,
				},
			},
			rotate: 0,
			cosinus: 0,
			sinus: 0,
			center: {
				x: 0,
				y: 0,
			},
			max: {
				x_axis: 0,
				y_axis: 0,
				width: 0,
				height: 0,
				cropx: 0,
				cropy: 0,
				topleft: {
					x: 0,
					y: 0,
				},
				placement: {
					x: 0,
					y: 0,
				},
			},
			placement: {
				x: 0,
				y: 0,
			},
			canvas: {
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				width: 0,
				height: 0,
			},
			draw: {
				x: 0,
				y: 0,
			},
		};
		vars.center = {
			x: vars.x_axis + vars.width / 2,
			y: vars.y_axis + vars.height / 2,
		};

		const objectscale = vars.cropwidth / vars.width;

		vars.max = {
			x_axis: vars.x_axis - vars.cropx / objectscale,
			y_axis: vars.y_axis - vars.cropy / objectscale,
			width: vars.maxwidth / objectscale * dpiScale,
			height: vars.maxheight / objectscale * dpiScale,
			cropx: 0,
			cropy: 0,
			topleft: {
				x: 0,
				y: 0,
			},
			placement: {
				x: 0,
				y: 0,
			},
		};
		vars.max.center = {
			x: vars.max.x_axis + vars.max.width / 2,
			y: vars.max.y_axis + vars.max.height / 2,
		};

		if (vars.flop) vars.angle = -vars.angle;
		if (vars.flip) vars.angle = -vars.angle;
		vars.rotate = Math.PI / 180 * vars.angle;
		vars.cosinus = Math.cos(vars.rotate);
		vars.sinus = Math.sin(vars.rotate);

		vars.topleft.x = ((-(vars.width) / 2 * Math.cos(vars.rotate)) - (-(vars.height) / 2 * Math.sin(vars.rotate))) + vars.center.x;
		vars.imagemap.topleft.x = ((-(vars.width + 2 * vars.borderwidth) / 2 * Math.cos(vars.rotate)) - (-(vars.height + 2 * vars.borderwidth) / 2 * Math.sin(vars.rotate))) + vars.center.x;

		vars.topleft.y = ((-(vars.width) / 2 * Math.sin(vars.rotate)) + (-(vars.height) / 2 * Math.cos(vars.rotate))) + vars.center.y;
		vars.imagemap.topleft.y = ((-(vars.width + 2 * vars.borderwidth) / 2 * Math.sin(vars.rotate)) + (-(vars.height + 2 * vars.borderwidth) / 2 * Math.cos(vars.rotate))) + vars.center.y;

		vars.max.topleft.x = ((-(vars.max.width) / 2 * Math.cos(vars.rotate)) - (-(vars.max.height) / 2 * Math.sin(vars.rotate))) + vars.max.center.x;
		vars.max.topleft.y = ((-(vars.max.width) / 2 * Math.sin(vars.rotate)) + (-(vars.max.height) / 2 * Math.cos(vars.rotate))) + vars.max.center.y;

		vars.topright.x = vars.topleft.x + vars.width * vars.cosinus;
		vars.imagemap.topright.x = vars.imagemap.topleft.x + (vars.width + 2 * vars.borderwidth) * vars.cosinus;

		vars.topright.y = vars.topleft.y + vars.width * vars.sinus;
		vars.imagemap.topright.y = vars.imagemap.topleft.y + (vars.width + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomright.x = vars.topright.x - vars.height * vars.sinus;
		vars.imagemap.bottomright.x = vars.imagemap.topright.x - (vars.height + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomright.y = vars.topright.y + vars.height * vars.cosinus;
		vars.imagemap.bottomright.y = vars.imagemap.topright.y + (vars.height + 2 * vars.borderwidth) * vars.cosinus;

		vars.bottomleft.x = vars.topleft.x - vars.height * vars.sinus;
		vars.imagemap.bottomleft.x = vars.imagemap.topleft.x - (vars.height + 2 * vars.borderwidth) * vars.sinus;

		vars.bottomleft.y = vars.topleft.y + vars.height * vars.cosinus;
		vars.imagemap.bottomleft.y = vars.imagemap.topleft.y + (vars.height + 2 * vars.borderwidth) * vars.cosinus;

		vars.placement = {
			x: vars.topleft.x,
			y: vars.topleft.y,
		};
		vars.max.placement = {
			x: vars.max.topleft.x,
			y: vars.max.topleft.y,
		};
		if (vars.flop) { // horizontal
			vars.placement.x = -vars.bottomright.x;
			const tempFlop = JSON.parse(JSON.stringify({
				topleft: vars.topleft,
				topright: vars.topright,
				bottomright: vars.bottomright,
				bottomleft: vars.bottomleft,
			}));
			vars.topleft.x = tempFlop.bottomleft.x;
			vars.topleft.y = tempFlop.topright.y;
			vars.topright.x = tempFlop.bottomright.x;
			vars.topright.y = tempFlop.topleft.y;
			vars.bottomright.x = tempFlop.topright.x;
			vars.bottomright.y = tempFlop.bottomleft.y;
			vars.bottomleft.x = tempFlop.topleft.x;
			vars.bottomleft.y = tempFlop.bottomright.y;
		}
		if (vars.flip) { // vertical
			if (!vars.flop) vars.placement.y = -vars.bottomright.y;
			else vars.placement.y = -vars.bottomleft.y;
			const tempFlip = JSON.parse(JSON.stringify({
				topleft: vars.topleft,
				topright: vars.topright,
				bottomright: vars.bottomright,
				bottomleft: vars.bottomleft,
			}));
			vars.topleft.x = tempFlip.bottomleft.x;
			vars.topleft.y = tempFlip.topright.y;
			vars.topright.x = tempFlip.bottomright.x;
			vars.topright.y = tempFlip.topleft.y;
			vars.bottomright.x = tempFlip.topright.x;
			vars.bottomright.y = tempFlip.bottomleft.y;
			vars.bottomleft.x = tempFlip.topleft.x;
			vars.bottomleft.y = tempFlip.bottomright.y;
		}

		vars.imagemap = {
			topleft: {
				x: (vars.imagemap.topleft.x) * scaling,
				y: (vars.imagemap.topleft.y) * scaling,
			},
			topright: {
				x: (vars.imagemap.topright.x) * scaling,
				y: (vars.imagemap.topright.y) * scaling,
			},
			bottomright: {
				x: (vars.imagemap.bottomright.x) * scaling,
				y: (vars.imagemap.bottomright.y) * scaling,
			},
			bottomleft: {
				x: (vars.imagemap.bottomleft.x) * scaling,
				y: (vars.imagemap.bottomleft.y) * scaling,
			},
		};

		const pagewidth = this.pageModel.width * scaling;
		const pageheight = this.pageModel.height * scaling;

		vars.canvas = {
			top: Math.max(
				0,
				Math.min(
					vars.imagemap.topleft.y,
					vars.imagemap.topright.y,
					vars.imagemap.bottomleft.y,
					vars.imagemap.bottomright.y,
				),
			),
			left: Math.max(
				0,
				Math.min(
					vars.imagemap.topleft.x,
					vars.imagemap.topright.x,
					vars.imagemap.bottomleft.x,
					vars.imagemap.bottomright.x,
				),
			),
			bottom: Math.min(
				pageheight + 2 * this.bleedMargin * scaling,
				Math.max(
					vars.imagemap.topleft.y,
					vars.imagemap.topright.y,
					vars.imagemap.bottomleft.y,
					vars.imagemap.bottomright.y,
				),
			),
			right: Math.min(
				pagewidth + 2 * this.bleedMargin * scaling,
				Math.max(
					vars.imagemap.topleft.x,
					vars.imagemap.topright.x,
					vars.imagemap.bottomleft.x,
					vars.imagemap.bottomright.x,
				),
			),
			width: 0,
			height: 0,
		};

		if (objectModel.type == 'text') {
			// The text of a text object can be bigger than the size of the object, therefore we make sure that nothing is cut off by extending the size of the canvas
			vars.canvas.left = 0;
			vars.canvas.right = pagewidth + 2 * this.bleedMargin * scaling;
			vars.canvas.bottom = pageheight + 2 * this.bleedMargin * scaling;
		} else if (objectModel.type == 'photo' && objectModel.borderimage) {
			const borderImageModel = ThemeDataModule.getBorderImage(objectModel.borderimage);
			if (borderImageModel) {
				const borderImageInnerWidth = borderImageModel.width - borderImageModel.borderwidth_left - borderImageModel.borderwidth_right;
				const borderscale = vars.width / borderImageInnerWidth;

				const borderWidthTop = Math.round(borderImageModel.borderwidth_top * borderscale);
				const borderwidthRight = Math.round(borderImageModel.borderwidth_right * borderscale);
				const borderwidthBottom = Math.round(borderImageModel.borderwidth_bottom * borderscale);
				const borderwidthLeft = Math.round(borderImageModel.borderwidth_left * borderscale);

				vars.canvas.left = Math.max(
					0,
					vars.canvas.left - borderwidthLeft,
				);
				vars.canvas.right = Math.min(
					pagewidth + 2 * this.bleedMargin * scaling,
					vars.canvas.right + borderwidthRight,
				);
				vars.canvas.top = Math.max(
					0,
					vars.canvas.top - borderWidthTop,
				);
				vars.canvas.bottom = Math.min(
					pageheight + 2 * this.bleedMargin * scaling,
					vars.canvas.bottom + borderwidthBottom,
				);
			}
		}

		vars.canvas.width = vars.canvas.right - vars.canvas.left;
		vars.canvas.height = vars.canvas.bottom - vars.canvas.top;

		vars.canvas.width += 2;
		vars.canvas.height += 2;

		vars.draw = {
			x: this.drawPage ? vars.placement.x : (vars.placement.x - vars.canvas.left / scaling + 1),
			y: this.drawPage ? vars.placement.y : (vars.placement.y - vars.canvas.top / scaling + 1),
		};

		return vars;
	}

	render() { // eslint-disable-line vue/require-render-return
		// Since the parent canvas has to mount first, it's *possible* that the context may not be
		// injected by the time this render function runs the first time.
		if (!this.canvas.context) return;
		const { context } = this.canvas;

		// Reset and clear canvas
		context.restore();
		context.clearRect(
			0,
			0,
			context.canvas.width,
			context.canvas.height,
		);

		// Save default context
		context.save();

		if (this.drawPage) {
			// Draw page background color
			context.fillStyle = this.pageModel.bgcolor && this.pageModel.bgcolor.length > 0 ? this.pageModel.bgcolor : '#FFFFFF';
			context.fillRect(
				0,
				0,
				context.canvas.width,
				context.canvas.height,
			);

			// Draw page background pattern
			if (this.pageModel._bgpattern) {
				context.save();

				const patternScale = this.scaling / 2;
				const pattern = context.createPattern(
					this.pageModel._bgpattern,
					'repeat',
				);
				if (pattern) {
					context.scale(
						patternScale,
						patternScale,
					);
					context.fillStyle = pattern;
					context.fillRect(
						0,
						0,
						context.canvas.width / patternScale,
						context.canvas.height / patternScale,
					);
				} else {
					throw new Error('Could not draw pattern');
				}

				context.restore();
			}
		}

		// Scale canvas
		context.scale(
			this.scaling,
			this.scaling,
		);

		if (this.drawPage) {
			// Draw page background image
			if (this.pageModel._bgimage) {
				const bgOffset = this.bleedMargin - this.pageModel.offset;
				context.drawImage(
					this.pageModel._bgimage,
					bgOffset,
					bgOffset,
					this.pageModel.width + 2 * this.pageModel.offset,
					this.pageModel.height + 2 * this.pageModel.offset,
				);
			}
		}

		if (this.drawPage
			&& (
				(this.pageModel.bgpattern && !this.pageModel._bgpattern)
				|| (this.pageModel.bgimage && !this.pageModel._bgimage)
			)
		) {
			// Show loader
			const charCode = 0xf251;
			const fontSize = Math.min(
				this.pageModel.width,
				this.pageModel.height,
			) * 0.25;

			// Draw blank background
			context.fillStyle = '#fff';
			context.fillRect(
				0,
				0,
				this.pageModel.width,
				this.pageModel.height,
			);

			// Set style for drawing spinner
			context.font = `${fontSize}px "Font Awesome 5 Pro"`;
			context.textAlign = 'center';
			context.textBaseline = 'middle';
			context.translate(
				this.pageModel.width / 2,
				this.pageModel.height / 2,
			);

			context.fillStyle = '#fff';
			context.fillRect(
				-fontSize / 2,
				-fontSize / 2,
				fontSize,
				fontSize,
			);

			context.fillStyle = 'rgba(0,0,0,0.5)';
			context.fillText(
				String.fromCharCode(charCode),
				0,
				0,
			);
		}

		// Draw objects on canvas
		this.drawObjects.forEach((objectModel) => {
			this.drawObject(objectModel);
		});

		// Draw address
		if (this.addressModel) {
			this.drawAddress(this.addressModel);
		}

		if (this.drawOverlay) {
			const offset = this.bleedMargin;
			const { width, height } = this.pageModel;

			if (ProductStateModule.getOverlayImage) {
				if (this.mirrorOverlay) {
					context.save();
					context.translate(
						width + 2 * offset,
						0,
					);
					context.scale(
						-1,
						1,
					);
				}

				context.drawImage(
					ProductStateModule.getOverlayImage,
					offset,
					offset,
					width,
					height,
				);

				if (this.mirrorOverlay) {
					context.restore();
				}
			} else if (this.offeringFrameImage
				&& this.offeringFrameModel
				&& this.offeringFrameModel.overpage
			) {
				const frameScale = this.offeringFrameModel.templateModel.width / (width + 2 * offset);
				context.drawImage(
					this.offeringFrameImage,
					0,
					0,
					this.offeringFrameModel.imageModel.width,
					this.offeringFrameModel.imageModel.height,
					-this.offeringFrameModel.templateModel.x / frameScale,
					-this.offeringFrameModel.templateModel.y / frameScale,
					this.offeringFrameModel.imageModel.width / frameScale,
					this.offeringFrameModel.imageModel.height / frameScale,
				);
			}
		}

		if (this.drawMask && this.offeringMaskImage && this.drawPage) {
			const offset = this.bleedMargin;
			const { width, height } = this.pageModel;

			context.save();
			context.globalCompositeOperation = 'destination-in';

			context.drawImage(
				this.offeringMaskImage,
				offset,
				offset,
				width,
				height,
			);

			context.restore();
		}
	}
}

export default toNative(DrawView);
