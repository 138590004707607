import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import { Vue } from 'vue-facing-decorator';

@Component({
	name: 'WebPushSoftAskView',
	emits: ['closeDialog'],
})
class WebPushSoftAskView extends Vue {
	accept() {
		this.closeDialog();
	}

	closeDialog() {
		this.$emit('closeDialog');
	}

	decline() {
		this.closeDialog();
	}
}

export default toNative(WebPushSoftAskView);
