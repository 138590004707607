import './defines';
import {
	SubscriptionsModule,
	UserModule,
} from 'store';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class SubscriptionsView extends Vue {
	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly fetch!: boolean;

	@Prop({
		required: true,
		type: String,
	})
	public readonly origin!: string;

	protected error = false;

	protected saved = false;

	protected savingAutomation = false;

	protected savingNewsletter = false;

	private get automationEmailModel() {
		return SubscriptionsModule.findWhere({
			channel: 'automation',
			method: 'email',
		});
	}

	protected get automationEmail() {
		return this.automationEmailModel
			? this.automationEmailModel.active
			: 0;
	}

	private set automationEmail(value) {
		if (this.automationEmailModel) {
			this.savingAutomation = true;

			SubscriptionsModule.saveEvent({
				data: {
					subscriptionid: this.automationEmailModel.id,
					event: value ? 'subscribe' : 'unsubscribe',
					origin: this.origin,
				},
			}).then(() => {
				this.savingAutomation = false;
			}).catch(() => {
				SubscriptionsModule.fetch({
					requestOptions: {
						params: {
							limit: 2,
						},
					},
				})
					.then(
						() => {
							this.savingAutomation = false;
						},
						() => {
						// Swallow error: no action required
						},
					);
			});
		}
	}

	private get newsletterEmailModel() {
		return SubscriptionsModule.findWhere({
			channel: 'newsletter',
			method: 'email',
		});
	}

	protected get newsletterEmail() {
		return this.newsletterEmailModel
			? this.newsletterEmailModel.active
			: 0;
	}

	private set newsletterEmail(value) {
		if (this.newsletterEmailModel) {
			this.savingNewsletter = true;

			SubscriptionsModule.saveEvent({
				data: {
					subscriptionid: this.newsletterEmailModel.id,
					event: value ? 'subscribe' : 'unsubscribe',
					origin: this.origin,
				},
			})
				.then(() => {
					this.savingNewsletter = false;
				}).catch(() => {
					SubscriptionsModule.fetch({
						requestOptions: {
							params: {
								limit: 2,
							},
						},
					}).then(() => {
						this.savingNewsletter = false;
					});
				});
		}
	}

	protected created() {
		if (this.fetch) {
			SubscriptionsModule.fetch({
				requestOptions: {
					params: {
						limit: 2,
					},
				},
			})
				.then(() => {
					this.addDefaults();
				})
				.catch(() => {
					// Swallow error: no action required
				});
		} else {
			this.addDefaults();
		}
	}

	private addDefaults() {
		if (!this.newsletterEmailModel && UserModule.id) {
			SubscriptionsModule.createModel({
				data: {
					active: 0,
					userid: UserModule.id,
					channel: 'newsletter',
					method: 'email',
				},
			});
		}
		if (!this.automationEmailModel && UserModule.id) {
			SubscriptionsModule.createModel({
				data: {
					active: 0,
					userid: UserModule.id,
					channel: 'automation',
					method: 'email',
				},
			});
		}
	}
}

export default toNative(SubscriptionsView);
