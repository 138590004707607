import './defines';
import ProductState from 'classes/productstate';
import DialogView from 'components/dialogs';
import EventBus from 'components/event-bus';
import IconComponent from 'components/icon';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import navigate from 'controllers/navigate';
import { RouteOptions } from 'interfaces/app';
import mitt, {
	type Emitter,
	type Handler,
} from 'mitt';
import deleteProduct from 'mutations/product/delete';
import routes from 'routes';
import * as DialogService from 'services/dialog';
import URL_PARAMETERS from 'settings/url-parameters';
import {
	AppStateModule,
	ConfigModule,
	ProductsModule,
	ProductStateModule,
	UserModule,
} from 'store';
// eslint-disable-next-line import/no-named-as-default
import ScrollBehavior from 'tools/scroll-behavior';
import _ from 'underscore';
import { Public } from 'utils/decorators';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import VueHammer, { VueTouch } from 'utils/vue-hammer';
import VueVisible from 'utils/vue-visible';
import SupportView from 'views/support';
import WebPushSoftAskView from 'views/web-push-soft-ask/template.vue';
import { createApp } from 'vue';
import { Vue } from 'vue-facing-decorator';
import {
	createRouter,
	createWebHistory,
	type NavigationFailure,
	type RouteLocationNormalizedGeneric,
} from 'vue-router';
import VueObserveVisibility from 'vue3-observe-visibility';
import Vue3TouchEvents, { Vue3TouchEventsOptions } from 'vue3-touch-events';
import Template from './template.vue';

type RouterStateHandlerEvents = {
	'routing-enabled': void;
}

class RouterStateHandler {
	private _emitter: Emitter<RouterStateHandlerEvents> = mitt();

	private _routingEnabled = false;

	public get routingEnabled() {
		return this._routingEnabled;
	}

	public set routingEnabled(value) {
		if (!this._routingEnabled) {
			this._routingEnabled = value;

			if (value) {
				this._emitter.emit('routing-enabled');
			}
		}
	}

	public off<Event extends keyof RouterStateHandlerEvents>(
		event: Event,
		handler: Handler<RouterStateHandlerEvents[Event]>,
	): void {
		this._emitter.off(
			event,
			handler,
		);
	}

	public on<Event extends keyof RouterStateHandlerEvents>(
		event: Event,
		handler: Handler<RouterStateHandlerEvents[Event]>,
	): void {
		this._emitter.on(
			event,
			handler,
		);
	}
}

const routerStateHandler = new RouterStateHandler();

export function configureRouter(appInstance: ReturnType<typeof createApp>): void {
	/**
	* Ignore the divider element, as it is not a valid HTML element
	* and should be ignored by Vue as if it were a component.
	*/
	appInstance.component(
		'IconComponent',
		IconComponent,
	);
	appInstance.use(VueObserveVisibility);
	appInstance.use<Vue3TouchEventsOptions>(
		Vue3TouchEvents,
		{},
	);
	appInstance.use(VueHammer);
	appInstance.component(
		'VTouch',
		VueTouch,
	);
	appInstance.use(VueVisible);

	const router = createRouter({
		history: createWebHistory('/app'),
		scrollBehavior(to, from, savedPosition) {
			if (
				to.hash
				&& to.hash !== '#'
			) {
				return {
					el: to.hash,
				};
			}

			if (
				to.name == 'Pages Overview'
				&& savedPosition
			) {
				return savedPosition;
			}

			return {
				left: 0,
				top: 0,
			};
		},
		routes,
	});
	let pendingRouteTo: RouteLocationNormalizedGeneric | undefined;
	let beforeRoutingEnabled: (() => void) | undefined;
	const onRoutingEnabledCallback = () => {
		routerStateHandler.off(
			'routing-enabled',
			onRoutingEnabledCallback,
		);
		beforeRoutingEnabled?.();

		if (pendingRouteTo) {
			router.replace(pendingRouteTo);
			pendingRouteTo = undefined;
		}
	};
	/**
	 * We need to wait only once for the first time for the app to be ready,
	 * before we can enable routing.
	 * After that, we can enable routing and navigate to the pending route.
	 * If not, then for example if a route needs the user to be logged in and
	 * this didn't happen yet, the login dialog will be shown even if the user
	 * is already logged in (since the token was not yet validated and the user
	 * was not loaded yet to the Store).
	 */
	beforeRoutingEnabled = router.beforeEach((to) => {
		/**
		 * If the routing is already enabled, we can navigate to the route.
		 */
		if (routerStateHandler.routingEnabled) {
			beforeRoutingEnabled?.();

			return to;
		}

		/**
		 * If not, then we save the route to navigate to it once the routing is enabled.
		 * If the `$router.replace()` is called before the routing is enabled, then this
		 * means some logic is trying to change something in the route, for example
		 * removing a query parameter, so we update the `pendingRouteTo` with the new route
		 * in case the `pendingRouteTo` already has a value set.
		 */
		pendingRouteTo = to;
		routerStateHandler.off(
			'routing-enabled',
			onRoutingEnabledCallback,
		);
		routerStateHandler.on(
			'routing-enabled',
			onRoutingEnabledCallback,
		);

		return false;
	});
	router.onError((err) => {
		if (!AppStateModule.online) {
			DialogService.openErrorDialog({
				header: {
					title: window.App.router.$t('dialogHeaderOffline'),
				},
				body: {
					content: window.App.router.$t('dialogTextLoadError'),
				},
			});
		} else if (err?.message) {
			let dialogErrorText = window.App.router.$t('dialogTextError');
			dialogErrorText += `\n\nError: ${err.message}`;
			DialogService.openErrorDialog({
				body: {
					content: dialogErrorText,
				},
			});
		}
	});
	appInstance.use(router);
	appInstance.use(
		ScrollBehavior,
		{
			router,
			delay: 100,
		},
	);
}

export function enableRouting() {
	routerStateHandler.routingEnabled = true;
}

@Component({
	components: {
		DialogView,
		WebPushSoftAskView,
		SupportView,
	},
	mixins: [Template],
})
class AppRouterView extends Vue {
	protected get showSupport() {
		return (
			ConfigModule.partnerID === 2
			&& AppStateModule.showSupportButton
		);
	}

	protected get webPushAvailable() {
		return AppStateModule.webPushAvailable;
	}

	protected componentKey = 0;

	public loading = true;

	protected created() {
		window.addEventListener(
			'online',
			this.setOnline,
		);
		window.addEventListener(
			'offline',
			this.setOffline,
		);

		if (this.$router) {
			this.$router.beforeEach((to, from, next) => {
				this.loading = true;
				next();
			});
			this.$router.afterEach((to) => {
				/**
				 * We only want to hide the loading spinner if we have a matched route
				 * and routing is enabled.
				 * If the router is not yet enabled we know that the pending route
				 * will be navigated again to once the router is enabled.
				 */
				if (
					to.matched.length
					&& routerStateHandler.routingEnabled
				) {
					this.loading = false;
				}
			});
		} else {
			this.loading = false;
		}
	}

	@Public()
	public back(): Decorated<void> {
		this.$router.go(-1);
	}

	@Public()
	public navigate(
		route: string,
		routeOptions: RouteOptions,
	): Decorated<void> {
		routeOptions = _.extend(
			{
				replace: false,
			},
			routeOptions,
		);

		if (routeOptions.replace) {
			this.$router
				.replace(route)
				.then(routeOptions.onComplete);
		} else {
			this.$router
				.push(route)
				.then(routeOptions.onComplete);
		}
	}

	@Public()
	public openProduct(
		projectId: number | 'latest',
		replace: boolean,
		action?: 'extendProjectLifetime' | 'removeProject',
	): Decorated<void> {
		// This route is only available to logged in users
		if (!UserModule.id) {
			EventBus.once(
				'auth:login',
				(success: boolean) => {
					if (success) {
						this.openProduct(
							projectId,
							replace,
						);
					} else {
						navigate.toStart();
					}
				},
			);

			auth.showLogin({
				hasclose: false,
			});
		} else if (projectId == 'latest') {
			ProductsModule
				.fetch({
					requestOptions: {
						params: {
							limit: 1,
							orderby: 'id DESC',
						},
					},
				})
				.then(() => {
					if (ProductsModule.models.length) {
						const productModel = ProductsModule.models[0];
						window.App.router.openProduct(
							productModel.id,
							true,
						);
					} else {
						navigate.toStart();
					}
				})
				.catch((err) => {
					this.$openErrorDialog({
						body: {
							content: err.message,
						},
					});
					navigate.toStart();
				});
		} else {
			// In case the user was deeplinked with custom attributes, we need to add these to the project data
			const customAttributes = (
				this.$route.query.hasOwnProperty(URL_PARAMETERS.customAttributes)
					? JSON.parse(this.$route.query[URL_PARAMETERS.customAttributes] as string)
					: undefined
			);

			ProductState
				.setup(
					projectId,
					{
						customAttributes,
						extendProjectLifetime: action && action === 'extendProjectLifetime',
					},
				)
				// Navigate into the project
				.then(() => {
					navigate.openProduct(
						projectId,
						{
							trigger: true,
							replace,
						},
					);

					const productModel = ProductStateModule.getProduct;
					if (productModel) {
						analytics.trackOpenProject(productModel);
					}

					if (
						action
						&& action === 'extendProjectLifetime'
					) {
						const closeAlert = this.$openAlertDialog({
							header: {
								title: this.$t('dialogs.projectLifetimeExtended.title'),
							},
							body: {
								content: this.$t('dialogs.projectLifetimeExtended.message'),
							},
							footer: {
								buttons: [
									{
										id: 'accept',
										text: this.$t('dialogs.projectLifetimeExtended.buttons.accept'),
										click: () => {
											closeAlert();
										},
									},
								],
							},
						});
					} else if (
						action
						&& action === 'removeProject'
					) {
						// Show confirmation dialog
						const closeConfirm = this.$openConfirmDialog({
							header: {
								title: this.$t('dialogHeaderRemoveProduct'),
							},
							body: {
								content: this.$t('dialogTextRemoveProduct'),
							},
							footer: {
								buttons: [
									{
										id: 'cancel',
										text: this.$t('dialogButtonCancel'),
										click: () => {
											closeConfirm();
										},
									},
									{
										id: 'accept',
										text: this.$t('dialogButtonRemoveProductOk'),
										click: () => {
											if (ProductStateModule.getProduct) {
												deleteProduct(projectId)
													.then(() => {
														navigate.toStart();
														closeConfirm();
													})
													.catch(() => {
														this.reload();
													});
											} else {
												navigate.toStart();
												closeConfirm();
											}
										},
									},
								],
							},
						});
					}
				})
				.catch((err) => {
					let dialogErrorText = window.App.router.$t('dialogTextError');
					if (err.message) {
						dialogErrorText += `\n\nError: ${err.message}`;
					}

					this.$openErrorDialog({
						body: {
							content: dialogErrorText,
						},
					});

					navigate.toStart();
				});
		}
	}

	@Public()
	public reload(): Decorated<void> {
		// We might be in loading state
		this.loading = false;

		// We have all components rerendered by changing the key of the master element
		// This forces a rerender of all subcomponents (and translations)
		this.componentKey += 1;
	}

	@Public()
	public setLoading(loading: boolean): Decorated<void> {
		this.loading = loading;
	}

	@Public()
	public removeURLParameter(parameter: string): Decorated<Promise<NavigationFailure | void | undefined>> {
		/**
		 * If the route is not matched, this means that either the router has not yet the routing
		 * enabled or that there is no actually matching route, one case could be that this function
		 * is called in the `checkToken()` (@see {@link ./src/js/ops/launch.ts}), if there is no
		 * matching route then the `query` property of the `this.$route` object will be empty.
		 * So we need to instead remove the parameter from the URL, replace the state and then
		 * replace the current $route with a new object that has the query object updated.
		 */
		if (!this.$route.matched.length) {
			const url = new URL(window.location.href);
			url.searchParams.delete(parameter);
			window.history.replaceState(
				null,
				'',
				url.toString(),
			);

			return this.$router.replace({
				path: url.pathname.replace(
					this.$router.options.history.base,
					'',
				),
				query: Object.fromEntries(url.searchParams),
			}) as Decorated<Promise<NavigationFailure | void | undefined>>;
		}

		const query = { ...this.$route.query };
		delete query[parameter];

		return this.$router.replace({ query }) as Decorated<Promise<NavigationFailure | void | undefined>>;
	}

	private setOnline() {
		AppStateModule.setOnline();
	}

	private setOffline() {
		AppStateModule.setOffline();
	}
}

export default toNative(AppRouterView);
