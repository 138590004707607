import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'IconComponent',
	mixins: [Template],
})
class IconComponent extends Vue {
	@Prop({
		default: false,
		description: 'Indicates if the icon should be disabled',
		type: Boolean,
	})
	public readonly disabled!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the icon should be flipped horizontally',
		type: Boolean,
	})
	public readonly flip?: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the icon should be flipped vertically',
		type: Boolean,
	})
	public readonly flop?: boolean;

	protected get iconClass(): string[] {
		const classes: string[] = [];

		if (this.disabled) {
			classes.push('icon-component-disabled');
		}
		if (this.flip) {
			classes.push('icon-component-flip');
		}
		if (this.flop) {
			classes.push('icon-component-flop');
		}

		return classes;
	}
}

export default toNative(IconComponent);
