import './defines';
import analytics from 'controllers/analytics';
import { ServiceEvent } from 'services/dialog';
import {
	AppDataModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import CountrySelectorItemView from 'views/selector-country-item.vue';
import { Vue } from 'vue-facing-decorator';
import Template from './template.vue';

interface Country {
	k: number;
	id: number;
	iso: string;
	name: string;
	suggested: boolean;
}

@Component({
	components: {
		CountrySelectorItemView,
	},
	emits: ['closeDialog'],
	mixins: [Template],
})
class CountrySelectorItemsView extends Vue {
	protected get items() {
		let countries: Country[] = [];
		AppDataModule.countries.forEach((countryModel) => {
			countries.push({
				k: countryModel.id,
				id: countryModel.id,
				iso: countryModel.iso.toLowerCase(),
				name: AppDataModule.getCountryName(countryModel.id),
				suggested: false,
			});
		});

		countries = _.sortBy(
			countries,
			'name',
		);

		if (countries.length > 10) {
			const userCountryISO = navigator.language && navigator.language.slice(-2).length > 0
				? navigator.language.slice(-2).toUpperCase()
				: null;
			if (userCountryISO) {
				const suggestedCountryModel = _.find(
					countries,
					(country) => userCountryISO.toLowerCase() == country.iso,
				);

				if (suggestedCountryModel) {
					countries.unshift({
						k: 0,
						id: suggestedCountryModel.id,
						iso: suggestedCountryModel.iso,
						name: suggestedCountryModel.name,
						suggested: true,
					});
				}
			}
		}

		return countries;
	}

	protected async selectCountry(id: number) {
		if (id === 0) {
			// Get the suggested country and set the id
			const country = _.findWhere(
				this.items,
				{ suggested: true },
			);
			if (country) {
				id = country.id;
			}
		}

		if (id) {
			const countryModel = AppDataModule.getCountry(id);

			if (countryModel) {
				await UserModule.setAndWaitForProductCategoriesData({
					countryid: countryModel.id,
					currency: countryModel.currency,
				});
			}
		}

		analytics.trackEvent(
			'Select country',
			{
				countryid: id,
			},
		);

		this.$emit(
			'closeDialog',
			new ServiceEvent({
				type: 'closeDialog',
				payload: id,
			}),
		);
	}
}

export default toNative(CountrySelectorItemsView);
