// eslint-disable-next-line vue/prefer-import-from-vue
import type { MethodOptionsExtended } from '@vue/runtime-core';
import type {
	ComponentOptionsBase,
	ComponentOptionsMixin,
	ComputedOptions,
} from 'vue';
import { createDecorator } from 'vue-facing-decorator';
import type { VModelConfig } from 'vue-facing-decorator/dist/option/vmodel';

export function Model(option?: VModelConfig | undefined) {
	return createDecorator(
		(
			// eslint-disable-next-line @typescript-eslint/ban-types
			options: ComponentOptionsBase<{}, {}, {}, ComputedOptions, MethodOptionsExtended, ComponentOptionsMixin, ComponentOptionsMixin, string[]>,
			memberKey,
		) => {
			const modelName = (
				option?.name
				|| memberKey
			);

			if (
				options.props
				&& !Object.prototype.hasOwnProperty.call(
					options.props,
					modelName,
				)
			) {
				options.props[modelName] = (
					option
					?? {}
				);
				options.props[modelName].event = `update:${modelName}`;

				if (!options.emits) {
					options.emits = [];
				}

				options.emits.push(`update:${modelName}`);
			}
		},
		{
			preserve: true,
		},
	);
}

export function Public(name?: string) {
	return createDecorator(
		(
			// eslint-disable-next-line @typescript-eslint/ban-types
			options: ComponentOptionsBase<{}, {}, {}, ComputedOptions, MethodOptionsExtended, ComponentOptionsMixin, ComponentOptionsMixin, string[]>,
			memberKey,
		) => {
			const modelName = (
				name
				|| memberKey
			);

			if (
				options.methods
				&& Object.prototype.hasOwnProperty.call(
					options.methods,
					modelName,
				)
			) {
				options.methods[modelName].public = true;
			}
		},
		{
			preserve: true,
		},
	);
}
