import './defines';
import PriceCalculator from 'classes/price-calculator';
import * as DB from 'interfaces/database';
import { ServiceEvent } from 'services/service-event';
import { OfferingCountForBulkDiscount } from 'settings/offerings';
import {
	AppDataModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import CoverView from 'views/cover.vue';
import PriceView from 'views/price';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

interface UpgradeOption {
	id: number;
	price?: number;
	offeringName: string;
	offeringSize: string;
	image?: string;
	featured: boolean;
	scale: number;
}

@Component({
	components: {
		CoverView,
		PriceView,
	},
	emits: [
		'closeDialog',
		'select',
		'updateDialog',
	],
	mixins: [Template],
})
class OfferingUpgradeView extends Vue {
	@Prop({
		required: true,
		type: Array,
	})
	public readonly offerings!: DB.OfferingModel[];

	@Prop({
		required: true,
		type: Object,
	})
	public readonly productModel!: DB.ProductModel;

	protected get currencyModel() {
		if (UserModule.currency) {
			return AppDataModule.getCurrency(UserModule.currency);
		}

		return undefined;
	}

	private options: UpgradeOption[] = [];

	protected mounted() {
		// Build pricing array to see if the prices differ for the selection
		const arrPricing = _.uniq(_.map(
			this.offerings,
			(offering) => {
				const objPrice = PriceCalculator.projectPrice({
					productid: this.productModel.id,
					offeringid: offering.id,
				});
				return objPrice.subTotal;
			},
		));

		const maxSize = _.max(
			this.offerings,
			(m) => m.width,
		) as DB.OfferingModel;

		this.offerings.forEach((offeringModel, idx) => {
			// Request product price
			const objPrice = PriceCalculator.projectPrice({
				productid: this.productModel.id,
				offeringid: offeringModel.id,
			});
			let { subTotal } = objPrice;

			const bulkProductType = _.findWhere(
				AppDataModule.bulkproducttypes,
				{
					offeringid: offeringModel.id,
				},
			);
			const bulkModel = AppDataModule.bulks.find(
				(model) => model.id === bulkProductType?.bulkid,
			);

			if (bulkModel) {
				const packageSize = OfferingCountForBulkDiscount(
					bulkModel,
					offeringModel,
					objPrice.totalPageCount,
					1,
				);

				const bulkQuantityModel = AppDataModule.bulkquantities.find(
					(m) => m.bulkid == bulkModel.id && m.from <= packageSize && m.to >= packageSize,
				);
				if (bulkQuantityModel) {
					subTotal *= (1 - bulkQuantityModel.relative / 100);
				}
			}

			const offeringName = AppDataModule.getOfferingName(
				offeringModel.id,
				false,
			);
			const featured = (idx === Math.floor(this.offerings.length / 2));
			const option: UpgradeOption = {
				id: offeringModel.id,
				offeringName,
				offeringSize: offeringModel.size,
				featured,
				image: undefined,
				scale: offeringModel.width / maxSize.width,
			};

			// If prices are different, add it to the selection choice text
			if (arrPricing.length > 1) {
				option.price = subTotal;
			}

			this.options.push(option);
		});
	}

	protected updated() {
		this.$emit('updateDialog');
	}

	protected imageLoaded() {
		this.$emit('updateDialog');
	}

	protected selectOption(offeringId: number) {
		const offeringModel = this.offerings.find((m) => offeringId == m.id);
		if (!offeringModel) {
			throw new Error('Could not find required offering model');
		}

		this.$emit(
			'select',
			new ServiceEvent({
				type: 'select',
				payload: offeringModel,
			}),
		);
		this.$emit('closeDialog');
	}
}

export default toNative(OfferingUpgradeView);
