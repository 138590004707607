import ProductState from 'classes/productstate';
import * as DB from 'interfaces/database';
import moment from 'moment';
import {
	ConfigModule,
	ProductStateModule,
} from 'store';
import getPixelUrl from 'tools/get-pixel-url';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';

@Component({
	name: 'CoverView',
	emits: ['clickImage'],
})
class CoverView extends Vue {
	@Prop({ required: false, type: Number })
	readonly offeringId!: DB.OfferingModel['id'];

	@Prop({ required: true, type: Object })
	readonly productModel!: DB.ProductModel;

	@Prop({ default: false, type: Boolean })
	readonly showPlaceHolder!: boolean;

	@Prop({ default: false, type: Boolean })
	readonly showRetry!: boolean;

	@Prop({ default: 'thumbnail', type: String })
	readonly size!: 'thumbnail'|'preview';

	private error = false;

	private fetchAttempts = 2;

	private loaded = false;

	private placeholderImg = getPixelUrl('#ffffff');

	get imageUrl() {
		let imageUrl = `${ConfigModule.projectImageBaseUrl}/${this.productModel.id}/${this.productModel.read_token}/${this.version}`;
		if (this.offeringId) {
			imageUrl += `?offeringid=${this.offeringId}`;
		}
		return imageUrl;
	}

	get saved() {
		if (ProductStateModule.productId && ProductStateModule.productId === this.productModel.id) {
			return ProductStateModule.getSaved;
		}

		return true;
	}

	get version() {
		if (ProductStateModule.productId && ProductStateModule.productId === this.productModel.id) {
			return ProductStateModule.version;
		}

		return moment(this.productModel.lastupdate).format('X');
	}

	created() {
		if (!this.saved) {
			// Product needs to be saved to server
			ProductState.save();
		}
	}

	retry() {
		this.fetchAttempts = 1;
		this.error = false;
	}

	clickImage() {
		this.$emit(
			'clickImage',
			this.$.vnode.key,
		);
	}

	onLoad() {
		this.loaded = true;
	}

	onLoadError() {
		this.error = true;

		this.fetchAttempts -= 1;
		if (this.fetchAttempts > 0) {
			window.setTimeout(
				() => {
					this.error = false;
				},
				100,
			);
		}
	}
}

export default toNative(CoverView);
