import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import merge from 'deepmerge';
import {
	AjaxOptions,
	CrossSellInspire,
	CrossSellSuggestion,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import moment from 'moment';
import * as DialogService from 'services/dialog';
import {
	ERRORS_INVALID_REQUEST_DATA,
	ERRORS_INVALID_RETURN_DATA,
} from 'settings/errors';
import {
	AppDataModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'products' })
export default class Products extends VuexModule {
	collection = reactive<DB.ProductModel[]>([]);

	fetched = false;

	modelUrl = '/api/product';

	offset = 0;

	totalRecords: number | null = null;

	private get collectionUrl() {
		return `/api/user/${this.context.rootState.user.id}/products`;
	}

	public get findWhere() {
		return (properties: Partial<DB.ProductModel>) => _.findWhere(
			this.collection,
			properties,
		);
	}

	public get getById() {
		return (id: number) => this.collection.find(
			(m) => m.id === id,
		);
	}

	public get models() {
		return _.sortBy(
			this.collection,
			(m) => -m.id,
		);
	}

	@Mutation
	private _addModel(data: DB.ProductModel) {
		this.collection.push(data);
		if (this.offset > 0) this.offset -= 1;
		if (this.totalRecords && this.totalRecords > 0) this.totalRecords += 1;
	}

	@Mutation
	private _addOffset(number: number) {
		this.offset += number;
	}

	@Mutation
	private _removeModel(id: number) {
		const i = _.findIndex(
			this.collection,
			(m) => m.id == id,
		);
		if (i >= 0) {
			this.collection.splice(
				i,
				1,
			);
			if (this.offset > 0) this.offset -= 1;
			if (this.totalRecords && this.totalRecords > 0) this.totalRecords -= 1;
		}
	}

	@Mutation
	public resetCollection() {
		this.collection = [];
	}

	@Mutation
	public resetMetaData() {
		this.fetched = false;
		this.offset = 0;
		this.totalRecords = null;
	}

	@Mutation
	private _setFetched(flag: boolean) {
		this.fetched = flag;
	}

	@Mutation
	private _setModel(data: DB.ProductModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		this.collection[i] = data;
	}

	@Mutation
	private _setTotalRecords(number: number) {
		this.totalRecords = number;
	}

	@Mutation
	public updateModel(data: DB.ProductModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		this.collection[i] = _.extend(
			model,
			data,
		);
	}

	@Mutation
	public updateModels(data: Partial<DB.ProductModel>) {
		_.each(
			this.collection,
			(model, i) => {
				this.collection[i] = _.extend(
					model,
					data,
				);
			},
		);
	}

	@Action({ rawError: true })
	public addModel(data: Partial<DB.ProductModel>): Promise<DB.ProductModel> {
		const { getters, commit } = this.context;

		if (!data.id) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}

		if (getters.getById(data.id)) {
			commit(
				'updateModel',
				data,
			);
		} else {
			commit(
				'_addModel',
				data,
			);
		}

		return Promise.resolve(
			getters.getById(data.id),
		);
	}

	@Action
	public addModels(arrData: DB.ProductModel[]) {
		const { dispatch } = this.context;

		_.each(
			arrData,
			(data) => {
				dispatch(
					'addModel',
					data,
				);
			},
		);
	}

	@Action({ rawError: true })
	public createModel({
		data,
		requestOptions,
		methodOptions,
	}: {
		data: Partial<DB.ProductModel>;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.ProductModel> {
		const { dispatch } = this.context;

		if (!data) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}
		if (data.userid != this.context.rootState.user.id) {
			return Promise.reject(
				new Error('Invalid userid'),
			);
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'post',
			url: this.modelUrl,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => dispatch(
				'addModel',
				response.data,
			));
	}

	@Action({ rawError: true })
	public crossSellSuggestions({
		id,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<CrossSellSuggestion[]> {
		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `/api/product/${id}/inspire`,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
		};

		requestOptions = (
			requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions
		);
		methodOptions = (
			methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions
		);

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then(async (response) => {
				if (!response.data) {
					throw new Error(ERRORS_INVALID_RETURN_DATA);
				}

				const countryModel = (
					UserModule.countryid
						? AppDataModule.getCountry(UserModule.countryid)
						: undefined
				);
				const crossSellSuggestions: CrossSellSuggestion[] = [];
				const crollSellInsipires: CrossSellInspire[] = response.data;
				await AppDataModule.findOfferings(crollSellInsipires.map((suggestion) => suggestion.offeringid));

				// eslint-disable-next-line no-restricted-syntax
				for (const suggestion of crollSellInsipires) {
					const offeringModel = AppDataModule.offerings.find((offering) => offering.id === suggestion.offeringid);

					if (
						countryModel
						&& offeringModel
					) {
						if (
							AppDataModule.findRegionOfferingLinkWhere({
								regionid: countryModel.regionid,
								offeringid: offeringModel.id,
							})
						) {
							const image = (
								suggestion.image.substring(
									0,
									4,
								) == 'http'
									? suggestion.image
									: `data:image/jpeg;base64, ${suggestion.image}`
							);
							crossSellSuggestions.push({
								id: suggestion.id,
								offeringid: suggestion.offeringid,
								image,
								themeid: (
									suggestion.themeid
									|| undefined
								),
								photoModels: (
									suggestion.photos
									|| undefined
								),
								showOfferingOption: suggestion.showOfferingOption,
							});
						}
					}
				}

				return crossSellSuggestions;
			});
	}

	@Action({ rawError: true })
	public destroyModel(id: number): Promise<void> {
		const { commit, dispatch } = this.context;

		return dispatch(
			'putModel',
			{
				id,
				data: {
					deleted: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
				},
			},
		).then(() => {
			commit(
				'_removeModel',
				id,
			);
		});
	}

	@Action({ rawError: true })
	public fetch({
		requestOptions,
		methodOptions,
	}: {
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}) {
		const {
			getters,
			commit,
		} = this.context;

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: getters.collectionUrl,
			params: {
				offset: this.offset,
				limit: 20,
				orderby: 'id DESC',
			},
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;
		const { limit } = requestOptions.params;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then(async (response) => {
				const offeringIds: number[] = [];

				// eslint-disable-next-line no-restricted-syntax
				for (const model of response.data as DB.ProductModel[]) {
					this.addModel(model);
					offeringIds.push(parseInt(
						`${model.group}${model.typeid}${model.variantid || '1'}`,
						10,
					));
				}

				if (offeringIds.length) {
					let closeLoader: (() => void) | undefined;

					try {
						closeLoader = DialogService.openLoaderDialog();
						await AppDataModule.fetchOfferingsData({
							offeringIds,
						});
					} catch (error) {
						// Swallow error: no action required
						if (typeof window.glBugsnagClient !== 'undefined') {
							window.glBugsnagClient.notify(
								error as Error,
								(event) => { event.severity = 'warning'; },
							);
						}
					} finally {
						closeLoader?.();
					}
				}

				commit(
					'_addOffset',
					limit,
				);
				commit(
					'_setFetched',
					true,
				);

				// Set total records and limit to collection properties
				if (response.headers.hasOwnProperty('x-total-records')) {
					commit(
						'_setTotalRecords',
						Math.max(
							0,
							parseInt(
								response.headers['x-total-records'],
								10,
							),
						),
					);
				}
			});
	}

	@Action({ rawError: true })
	public fetchModel({
		id,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.ProductModel> {
		const { getters, dispatch } = this.context;

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `${this.modelUrl}/${id}`,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				dispatch(
					'addModel',
					response.data,
				);
				return getters.getById(response.data.id);
			});
	}

	@Action({ rawError: true })
	public putModel({
		id,
		data,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		data: Partial<DB.ProductModel>;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<void> {
		const { getters, commit, rootState } = this.context;

		if (!id) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}
		if (!data) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}
		if (data.hasOwnProperty('userid') && data.userid != rootState.user.id) {
			return Promise.reject(
				new Error('Invalid userid'),
			);
		}

		const model = getters.getById(id);
		if (!model) {
			return Promise.reject(
				new Error('Model does not exist'),
			);
		}

		const currentModelData = JSON.parse(JSON.stringify(model));
		const newModelData = _.extend(
			{ id },
			data,
		);

		commit(
			'updateModel',
			newModelData,
		);

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `${this.modelUrl}/${id}`,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data: newModelData,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				commit(
					'_setModel',
					response.data,
				);
			})
			.catch((err) => {
				commit(
					'_setModel',
					currentModelData,
				);
				throw err;
			});
	}
}
