import './defines';
import ProductState from 'classes/productstate';
import Theme from 'classes/theme';
import analytics from 'controllers/analytics';
import * as DB from 'interfaces/database';
import { OfferingGroups } from 'settings/offerings';
import {
	ProductStateModule,
	ThemeDataModule,
	ThemeStateModule,
} from 'store';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import LayoutPickerItemView from 'views/picker-layout-item.vue';
import {
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		LayoutPickerItemView,
	},
	emits: [
		'closeDialog',
		'updateDialog',
	],
	mixins: [Template],
})
class LayoutPickerView extends Vue {
	@Prop({
		default: true,
		type: Boolean,
	})
	public readonly isSwitch!: boolean;

	protected get collection() {
		return ThemeStateModule.layoutCollection;
	}

	protected get isLoading() {
		return !ThemeDataModule.getFetched;
	}

	protected get productModel() {
		return ProductStateModule.getProduct;
	}

	protected created() {
		Theme
			.fetchData(false)
			.then(() => {
				this.$emit('updateDialog');
			});
	}

	protected updated() {
		this.$emit('updateDialog');
	}

	private setLayout(layoutModel: DB.LayoutModel) {
		if (this.isSwitch) {
			analytics.trackEvent(
				'Change Layout',
				{
					category: 'Button',
				},
			);
		}

		const closeLoader = this.$openLoaderDialog();

		ProductState
			.changeLayout(
				layoutModel.id,
				this.isSwitch,
			)
			.then(() => {
				ProductStateModule.pushHistory();
			})
			.finally(() => {
				closeLoader();
				this.close();
			})
			.catch(() => {
				// Swallow error: no action required
			});
	}

	private close() {
		this.$emit('closeDialog');
	}

	protected imageLoaded() {
		this.$emit('updateDialog');
	}

	protected selectLayout(layoutModel: DB.LayoutModel) {
		const productModel = ProductStateModule.getProduct;

		if (
			// Cards always change immediately, no need to ask for confirmation
			// Same for single pagers
			(
				productModel
				&& OfferingGroups(
					productModel.group,
					['Cards', 'BasicProducts'],
				)
			)
			|| !this.isSwitch
		) {
			this.setLayout(layoutModel);
		} else {
			const closeConfirm = this.$openConfirmDialog({
				header: {
					title: this.$t('dialogHeaderChangeTheme'),
				},
				body: {
					content: this.$t('dialogTextChangeTheme'),
				},
				footer: {
					buttons: [
						{
							id: 'confirm',
							text: this.$t('dialogButtonChangeThemeOk'),
							click: () => {
								this.setLayout(layoutModel);
								closeConfirm();
							},
						},
						{
							id: 'cancel',
							text: this.$t('dialogButtonChangeThemeCancel'),
							click: () => {
								closeConfirm();
							},
						},
					],
				},
			});
		}
	}
}

export default toNative(LayoutPickerView);
